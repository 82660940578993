import moment, { Moment } from "moment";
import {
  BloqueEntrenamientoCategoria,
  UnidadSerie,
} from "app/entrenamientos/models/BloqueEjercicio";
import { Entrenamiento } from "app/entrenamientos/models/Entrenamiento";
import { PlantillaEntrenamiento } from "app/plantilla-entrenamiento/models/PlantillaEntrenamiento";

export type CreateEntrenamientoSerieFormType = {
  cantidad: string;
  carga: string;
  usa_mancuernas: boolean;
};
export type CreateEntrenamientoBloqueFormType = {
  ejercicio_id: number;
  descanso: string;
  categoria: BloqueEntrenamientoCategoria;
  unidad_serie: UnidadSerie;
  series: CreateEntrenamientoSerieFormType[];
};

export type CreateEntrenamientoRutinaFormType = {
  dia: number;
  bloques: CreateEntrenamientoBloqueFormType[];
};

export type CreateEntrenamientoFormType = {
  id_socio: number;
  fecha_inicio: Moment;
  fecha_vencimiento: Moment;
  objetivo: string;
  observacion: string;
  rutinas: CreateEntrenamientoRutinaFormType[];
};

export type CreateEntrenamientoRoutingType = {
  formValues: Omit<
    CreateEntrenamientoFormType,
    "fecha_inicio" | "fecha_vencimiento"
  > & {
    fecha_inicio: string;
    fecha_vencimiento: string;
  };
  filtrosEjercicios: {
    tipos: string[];
    gruposMuscularesId: number[];
  };
  bloqueValues: {
    rutinaDia: number;
    bloqueIndex: number;
    ejercicio_id: number | undefined;
    returnUrl: string;
  };
} | null;

export const mapEntrenamientoModelToFormType = (
  data: Entrenamiento,
  idSocio: number
): CreateEntrenamientoFormType => ({
  ...data,
  id_socio: idSocio,
  rutinas: data.rutinas.map((rutina) => ({
    dia: Number(rutina.dia),
    bloques: rutina.bloques.map((bloque) => ({
      ...bloque,
      descanso: String(bloque.descanso),
      ejercicio_id: bloque.ejercicio.id,
      series: bloque.series.map((serie) => ({
        ...serie,
        cantidad: String(serie.cantidad),
        carga: String(serie.carga),
      })),
    })),
  })),
});

export const createEntrenamientoFormValueRoutingFromEntrenamiento = (
  entrenamiento: Entrenamiento,
  socioId: number
): CreateEntrenamientoRoutingType => ({
  formValues: {
    ...mapEntrenamientoModelToFormType(entrenamiento, socioId),
    id_socio: socioId,
    fecha_inicio: moment().format("YYYY-MM-DD"),
    fecha_vencimiento: moment().add(1, "month").format("YYYY-MM-DD"),
  },
  filtrosEjercicios: {
    tipos: [],
    gruposMuscularesId: [],
  },
  bloqueValues: {
    bloqueIndex: 0,
    rutinaDia: 0,
    ejercicio_id: 0,
    returnUrl: "",
  },
});
