import { PageContainer } from "layout/components";
import { PageBody } from "layout/components/PageBody";
import { Link, useLocation } from "react-router-dom";
import { EjercicioCardComponent } from "app/entrenamientos/components/EjercicioCardComponent";
import { useSuperQuery } from "@vadiun/react-hooks";
import { EjerciciosRepository } from "app/ejercicios/services/ejerciciosRepository";
import { Ejercicio } from "app/ejercicios/models/Ejercicio";
import { CreateEntrenamientoRoutingType } from "app/entrenamientos/forms/CreateEntrenamientoForm/CreateEntrenamientoFormType";
import { InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";

interface Props {
  repo: EjerciciosRepository;
}

export const ListaEjerciciosPage = ({ repo }: Props) => {
  const [search, setSearch] = useState("");
  const ejerciciosQuery = useSuperQuery(repo.getAll);
  const location = useLocation();
  const locationState = location.state as CreateEntrenamientoRoutingType;

  const ejercicios: Ejercicio[] = (ejerciciosQuery.data || []).filter((exe) => {
    if (!locationState) {
      return true;
    }
    const { gruposMuscularesId, tipos } = locationState.filtrosEjercicios;

    let matcheaTipo = true;
    if (tipos && tipos.length > 0) {
      matcheaTipo = tipos.some((f) => f === exe.tipo);
    }
    let matcheaGrupoMuscular = true;
    if (gruposMuscularesId && gruposMuscularesId.length > 0) {
      matcheaGrupoMuscular = exe.grupos_musculares.some((grupo) => {
        return locationState.filtrosEjercicios.gruposMuscularesId.some(
          (grupoFiltro) => {
            return grupoFiltro === grupo.id;
          }
        );
      });
    }
    let matcheaSearch = exe.nombre.toLowerCase().includes(search.toLowerCase());
    return matcheaTipo && matcheaGrupoMuscular && matcheaSearch;
  });

  return (
    <div>
      <PageContainer title="Ejercicios" child />
      <PageBody>
        <TextField
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
          placeholder="Buscar"
          fullWidth
          className="mb-8"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaSearch />
              </InputAdornment>
            ),
          }}
        />
        {ejercicios.length === 0
          ? "No hay elementos que coincidan con la búsqueda"
          : null}
        <div className="grid grid-cols-2 gap-10 ">
          {ejercicios.map((ejercicio) => {
            return (
              <div key={ejercicio.id}>
                <EjercicioCardComponent ejercicio={ejercicio}>
                  <Link
                    key={ejercicio.id}
                    to={"../bloque/create"}
                    state={{
                      ...locationState,
                      bloqueValues: {
                        ...locationState!.bloqueValues,
                        ejercicio_id: ejercicio.id,
                      },
                    }}
                  >
                    <span className="text-blue-700">SELECCIONAR</span>
                  </Link>
                </EjercicioCardComponent>
              </div>
            );
          })}
        </div>
      </PageBody>
    </div>
  );
};
