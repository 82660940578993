import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button } from "@vadiun/react-components";
import { createInitialValues } from "./initialValues";
import { FiltrosEjerciciosFormType } from "./FiltrosEjerciciosFormType";
import { Checkbox, RadioGroup } from "formik-mui";
import {
  Checkbox as MUICheckbox,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  GrupoMuscular,
  GrupoMuscularCategoria,
} from "app/ejercicios/models/GrupoMuscular";
import { EjercicioTipo } from "app/ejercicios/models/Ejercicio";
import { FormikOnFieldChange } from "shared/components/FormikOnFieldChange";
import { validation } from "./validation";

interface Props {
  onSubmit: (value: FiltrosEjerciciosFormType) => Promise<void>;
  initialValues?: FiltrosEjerciciosFormType;
  categoriasList: GrupoMuscularCategoria[];
  tiposList: EjercicioTipo[];
  gruposMuscularesList: GrupoMuscular[];
  onNoFilters: () => void;
}

export function FiltrosEjerciciosForm({
  onSubmit,
  onNoFilters,
  initialValues = createInitialValues(),
  categoriasList,
  tiposList,
  gruposMuscularesList,
}: Props) {
  return (
    <Formik<FiltrosEjerciciosFormType>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validation}
    >
      {(formik) => (
        <Form className="w-full">
          <FormikOnFieldChange<FiltrosEjerciciosFormType>
            field="categoria"
            onChange={() => {
              formik.setFieldValue("gruposMuscularesId", []);
            }}
          />
          <h1 className="mb-2 text-xl">Categoría</h1>

          <Field
            name="categoria"
            label="categoria *"
            className="mb-5"
            component={RadioGroup}
          >
            <div className="flex flex-row">
              {categoriasList.map((option) => (
                <FormControlLabel
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </div>
          </Field>
          <h1 className="mb-2 text-xl">Grupos Musculares</h1>
          <div className="flex flex-col border-l-4">
            {gruposMuscularesList
              .filter((option) => option.categoria === formik.values.categoria)
              .map((option, index) => (
                <div className="flex flex-row items-center">
                  <MUICheckbox
                    checked={formik.values.gruposMuscularesId.includes(
                      option.id
                    )}
                    id={"grupo" + option.id}
                    onChange={(event) => {
                      if (event.target.checked) {
                        formik.setFieldValue(
                          "gruposMuscularesId",
                          formik.values.gruposMuscularesId.concat(option.id)
                        );
                      } else {
                        formik.setFieldValue(
                          "gruposMuscularesId",
                          formik.values.gruposMuscularesId.filter(
                            (id) => id !== option.id
                          )
                        );
                      }
                    }}
                  />
                  <label htmlFor={"grupo" + option.id}>{option.nombre}</label>
                </div>
              ))}
          </div>
          <ErrorMessage
            name="gruposMuscularesId"
            render={(msg) => <span className="mt-4 text-red-500">{msg}</span>}
          />
          <div className="mt-8 flex items-center gap-8">
            <h1 className=" text-xl">Tipo de ejercicio</h1>
            <Button onClick={() => formik.setFieldValue("tipos", tiposList)}>
              Seleccionar todos
            </Button>
          </div>

          <div className="mb-5 flex flex-col border-l-4">
            {tiposList.map((option) => (
              <div className="flex flex-row items-center">
                <Field
                  name="tipos"
                  value={option}
                  label={option}
                  type="checkbox"
                  id={"tipo" + option}
                  component={Checkbox}
                />
                <label htmlFor={"tipo" + option}>{option}</label>
              </div>
            ))}
          </div>

          <div className="col-span-6 flex justify-end gap-2">
            <Button type="button" onClick={onNoFilters}>
              Continuar sin filtros
            </Button>
            <Button type="submit" variant="contained" color="green">
              Buscar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
