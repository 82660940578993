const dev = {
  //backEnd: "https://american-sport.vadiun.net/api/",
  backEnd: "https://back.asplan.ar/api/",
  production: false,
};

const prod = {
  backEnd: "https://back.asplan.ar/api/",
  production: true,
};

export const environment = process.env.REACT_APP_STAGE === "prod" ? prod : dev;
