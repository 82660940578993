import { CreatePlantillaEntrenamientoFormType } from "app/plantilla-entrenamiento/forms/CreatePlantillaEntrenamientoForm/CreatePlantillaEntrenamientoFormType";

export const createInitialValues =
  (): CreatePlantillaEntrenamientoFormType => ({
    nombre: "",
    objetivo: "",
    observacion: "",
    rutinas: [
      {
        dia: 1,
        bloques: [],
      },
      {
        dia: 2,
        bloques: [],
      },
      {
        dia: 3,
        bloques: [],
      },
      {
        dia: 4,
        bloques: [],
      },
      {
        dia: 5,
        bloques: [],
      },
    ],
  });
