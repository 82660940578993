import { Entrenamiento } from "app/entrenamientos/models/Entrenamiento";

interface ISocio {
  name: string;
  dni: string;
  email: string;
  nro_socio: string;
  photo: string;
  sede: string;
  id?: string;
  cant_asistencias: number;
  ultimo_entrenamiento: Entrenamiento | null;
}

export class Socio {
  name: string;
  dni: string;
  id?: string;
  email: string;
  nro_socio: string;
  photo: string;
  sede: string;
  cant_asistencias: number;
  ultimo_entrenamiento: Entrenamiento | null;

  constructor(args: ISocio) {
    this.name = args.name;
    this.dni = args.dni;
    this.id = args.id;
    this.email = args.email;
    this.nro_socio = args.nro_socio;
    this.photo = args.photo;
    this.sede = args.sede;
    this.cant_asistencias = args.cant_asistencias;
    this.ultimo_entrenamiento = args.ultimo_entrenamiento;
  }
}
