import { PlantillaEntrenamientoNavigation } from "app/plantilla-entrenamiento/navigation";
import { EntrenamientosNavigation } from "app/entrenamientos/navigation";
import { EjerciciosNavigation } from "app/ejercicios/navigation";
import { ProfesorNavigation } from "app/profesor/navigation";
import { SocioNavigation } from "app/socio/navigation";
import { Landing } from "layout/pages/Landing";
import { Route, Routes } from "react-router-dom";
import { MainLayout } from "layout/components";

export const MainNavigation = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="landing" element={<Landing />} />
        <Route path="profesor/*" element={<ProfesorNavigation />} />

        <Route path="ejercicios/*" element={<EjerciciosNavigation />} />
        <Route path="socios/*" element={<SocioNavigation />} />
        <Route path="entrenamientos/*" element={<EntrenamientosNavigation />} />
        <Route
          path="plantilla-entrenamiento/*"
          element={<PlantillaEntrenamientoNavigation />}
        />
      </Route>
    </Routes>
  );
};
