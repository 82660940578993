import { EntrenamientosRepository } from "app/entrenamientos/services/entrenamientosRepository";
import { PageContainer } from "layout/components";
import { useSuperQuery } from "@vadiun/react-hooks";
import { useParams } from "react-router-dom";
import { EntrenamientoDetalleComponent } from "app/entrenamientos/components/EntrenamientoDetalleComponent";
import { EntrenamientoDetailPrintComponent } from "../components/RutinasDetailPrintComponent";
import { Button } from "@vadiun/react-components";
import { FaPrint } from "react-icons/fa";
import * as html2pdf from "html2pdf.js";

interface Props {
  repo: EntrenamientosRepository;
}

export const EntrenamientoPage = ({ repo }: Props) => {
  const params = useParams<{ id: string }>();
  const entrenamientoQuery = useSuperQuery(
    () => repo.getActualSocioById(Number(params.id)),
    {
      showSpinner: true,
    }
  );

  const printEntrenamiento = (id: number) => {
    var element = document.getElementById("print-entrenamiento-" + id);
    html2pdf.default(element, {
      filename: `entrenamiento.pdf`,
    });
  };

  if (entrenamientoQuery.data === undefined) {
    return null;
  }
  return (
    <PageContainer
      title="Plan de entrenamiento"
      child
      toolbar={
        <Button
          variant="outlined"
          shape="circle"
          onClick={() => printEntrenamiento(entrenamientoQuery.data!.id)}
        >
          <FaPrint />
        </Button>
      }
    >
      <EntrenamientoDetalleComponent entrenamiento={entrenamientoQuery.data} />
      <div className="h-0 overflow-hidden">
        {entrenamientoQuery.data ? (
          <EntrenamientoDetailPrintComponent
            entrenamiento={entrenamientoQuery.data}
          />
        ) : null}
      </div>
    </PageContainer>
  );
};
