import { PageContainer } from "layout/components";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { PageBody } from "layout/components/PageBody";
import { useSuperMutation, useSuperQuery } from "@vadiun/react-hooks";
import { EjerciciosRepository } from "app/ejercicios/services/ejerciciosRepository";
import { useEffect, useState } from "react";
import { createInitialValues } from "app/plantilla-entrenamiento/forms/CreatePlantillaEntrenamientoForm/initialValues";
import {
  CreatePlantillaEntrenamientoFormType,
  CreatePlantillaEntrenamientoRoutingType,
  mapPlantillaEntrenamientoModelToFormType,
} from "app/plantilla-entrenamiento/forms/CreatePlantillaEntrenamientoForm/CreatePlantillaEntrenamientoFormType";
import { PlantillaEntrenamientoRepository } from "../services/plantillaEntrenamientoRepository";
import { CreatePlantillaEntrenamientoForm } from "app/plantilla-entrenamiento/forms/CreatePlantillaEntrenamientoForm/CreatePlantillaEntrenamientoForm";

interface Props {
  repo: PlantillaEntrenamientoRepository;
  ejerciciosRepo: EjerciciosRepository;
}

export const EditPlantillaEntrenamientoPage = ({
  repo,
  ejerciciosRepo,
}: Props) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const params = useParams<{ id: string }>();
  const [initialValues, setInitialValues] =
    useState<CreatePlantillaEntrenamientoFormType>({
      ...createInitialValues(),
    });
  const location = useLocation();
  const locationState =
    location.state as CreatePlantillaEntrenamientoRoutingType;
  const plantillaEntrenamientoQuery = useSuperQuery(() => repo.get(params.id!));

  const updateEntrenamientoMutation = useSuperMutation(repo.update, {
    showSpinner: true,
  });

  useEffect(() => {
    if (locationState) {
      setInitialValues(locationState.formValues);
    }
  }, []);

  useEffect(() => {
    const data = plantillaEntrenamientoQuery.data!;
    if (data === undefined || locationState) {
      return;
    }
    setInitialValues(mapPlantillaEntrenamientoModelToFormType(data));
  }, [plantillaEntrenamientoQuery.data]);

  const ejerciciosQuery = useSuperQuery(ejerciciosRepo.getAllWithDeleted, {
    showSpinner: true,
  });

  console.log(locationState);

  return (
    <PageContainer
      title={"Edición de plan predeterminado"}
      child
      navigateBackTo={"/main/plantilla-entrenamiento/listado"}
    >
      <PageBody>
        {ejerciciosQuery.data && (
          <CreatePlantillaEntrenamientoForm
            onSubmit={async (values) => {
              await updateEntrenamientoMutation.mutate({
                ...values,
                id: Number(params.id!),
              });
              navigate("/main/plantilla-entrenamiento/listado");
            }}
            selectedDia={locationState?.bloqueValues.rutinaDia ?? 1}
            ejercicios={ejerciciosQuery.data ?? []}
            initialValues={initialValues}
            returnUrl={"/main/plantilla-entrenamiento/" + params.id + "/edit"}
          />
        )}
      </PageBody>
    </PageContainer>
  );
};
