import { PageContainer } from "layout/components";
import { useLocation, useNavigate } from "react-router-dom";
import { PageBody } from "layout/components/PageBody";
import { useSuperMutation, useSuperQuery } from "@vadiun/react-hooks";
import { EjerciciosRepository } from "app/ejercicios/services/ejerciciosRepository";
import { useEffect, useState } from "react";
import {
  CreatePlantillaEntrenamientoFormType,
  CreatePlantillaEntrenamientoRoutingType,
} from "app/plantilla-entrenamiento/forms/CreatePlantillaEntrenamientoForm/CreatePlantillaEntrenamientoFormType";
import { createInitialValues } from "app/plantilla-entrenamiento/forms/CreatePlantillaEntrenamientoForm/initialValues";
import { CreatePlantillaEntrenamientoForm } from "app/plantilla-entrenamiento/forms/CreatePlantillaEntrenamientoForm/CreatePlantillaEntrenamientoForm";
import { PlantillaEntrenamientoRepository } from "app/plantilla-entrenamiento/services/plantillaEntrenamientoRepository";

interface Props {
  repo: PlantillaEntrenamientoRepository;
  ejerciciosRepo: EjerciciosRepository;
}

export const CreatePlantillaEntrenamientoPage = ({
  repo,
  ejerciciosRepo,
}: Props) => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] =
    useState<CreatePlantillaEntrenamientoFormType>({
      ...createInitialValues(),
    });

  const location = useLocation();
  const locationState =
    location.state as CreatePlantillaEntrenamientoRoutingType;

  const createPlantillaEntrenamientoMutation = useSuperMutation(repo.create, {
    showSpinner: true,
  });

  const ejerciciosQuery = useSuperQuery(ejerciciosRepo.getAll, {
    showSpinner: true,
  });

  useEffect(() => {
    if (locationState) {
      setInitialValues(locationState.formValues);
    }
  }, []);

  return (
    <PageContainer
      title={"Nuevo plan prederminado"}
      child
      navigateBackTo={"../listado"}
    >
      <PageBody>
        {ejerciciosQuery.data && (
          <CreatePlantillaEntrenamientoForm
            onSubmit={async (values) => {
              await createPlantillaEntrenamientoMutation.mutate(values);
              navigate("../listado");
            }}
            selectedDia={locationState?.bloqueValues.rutinaDia ?? 1}
            ejercicios={ejerciciosQuery.data ?? []}
            initialValues={initialValues}
            returnUrl={"/main/plantilla-entrenamiento/create"}
          />
        )}
      </PageBody>
    </PageContainer>
  );
};
