import { MiPerfilPage } from "app/socio/pages/MiPerfilPage";
import { SocioRepository } from "app/socio/services/socioRepository";
import { PerfilSocioPage } from "app/socio/pages/PerfilSocioPage";
import { Route, Routes } from "react-router-dom";
import { AltaSocioPage } from "../pages/AltaSocioPage";
import { ListaSociosPage } from "../pages/ListaSociosPage";
import { EntrenamientosRepository } from "app/entrenamientos/services/entrenamientosRepository";
import { PlantillaEntrenamientoRepository } from "app/plantilla-entrenamiento/services/plantillaEntrenamientoRepository";
import { ProfesorRepository } from "app/profesor/services/profesorRepository";

const socioRepo = new SocioRepository();
const entrenamientosRepo = new EntrenamientosRepository();
const plantillaEntrenamientoRepo = new PlantillaEntrenamientoRepository();
const profeRepo = new ProfesorRepository();

export const SocioNavigation = () => {
  return (
    <Routes>
      <Route
        path="/:id"
        element={
          <PerfilSocioPage
            repo={socioRepo}
            entrenamientosRepo={entrenamientosRepo}
            plantillaEntrenamientoRepo={plantillaEntrenamientoRepo}
          />
        }
      />
      <Route path="/me/profile" element={<MiPerfilPage repo={socioRepo} />} />
      <Route path="/alta" element={<AltaSocioPage repo={socioRepo} />} />
      <Route
        path="/listado"
        element={<ListaSociosPage repo={socioRepo} repoProfe={profeRepo} />}
      />
    </Routes>
  );
};
