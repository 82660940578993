import React, { FC } from "react";

interface Props {
  className?: string;
}

export const PageBody: FC<Props> = (_props) => {
  const { className, ...props } = _props;
  return (
    <div
      className={`min-h-full bg-white p-8 sm:w-full sm:space-y-4 md:space-y-0 lg:space-x-4 xl:px-40 ${
        className ?? ""
      }`}
    >
      {props.children}
    </div>
  );
};
