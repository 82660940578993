import { CreateEntrenamientoFormType } from "./CreateEntrenamientoFormType";
import moment from "moment";

export const createInitialValues = (): CreateEntrenamientoFormType => ({
  id_socio: 0,
  fecha_inicio: moment(),
  fecha_vencimiento: moment().add(1, "month"),
  objetivo: "",
  observacion: "",
  rutinas: [
    {
      dia: 1,
      bloques: [],
    },
    {
      dia: 2,
      bloques: [],
    },
    {
      dia: 3,
      bloques: [],
    },
    {
      dia: 4,
      bloques: [],
    },
    {
      dia: 5,
      bloques: [],
    },
  ],
});
