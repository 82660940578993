import React from "react";
import { Field, Form, Formik } from "formik";
import { Button, FormikImageUpload } from "@vadiun/react-components";
import { createInitialValues } from "./initialValues";
import { EdicionSocioFormType } from "./EdicionSocioFormType";
import { validation } from "./validation";
import { TextField } from "formik-mui";

interface Props {
  onSubmit: (value: EdicionSocioFormType) => Promise<void>;
  initialValues?: EdicionSocioFormType;
}

export function EdicionSocioForm({
  onSubmit,
  initialValues = createInitialValues(),
}: Props) {
  return (
    <Formik<EdicionSocioFormType>
      initialValues={initialValues}
      validationSchema={validation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className="grid w-full grid-cols-6 gap-6">
          <div className="col-span-3">
            <Field
              name="photo"
              className="aspect-w-1 aspect-h-1"
              component={FormikImageUpload}
            />
          </div>

          <Field
            name="name"
            label="Nombre y apellido *"
            className="col-span-6"
            component={TextField}
            disabled
          />

          <Field
            name="dni"
            label="Dni *"
            className="col-span-6"
            component={TextField}
            disabled
          />

          <Field
            name="nro_socio"
            label="Nro socio *"
            className="col-span-6"
            type="number"
            component={TextField}
            disabled
          />

          <Field
            name="email"
            label="Email *"
            className="col-span-6"
            component={TextField}
            disabled
          />

          <div className="col-span-6 flex justify-end">
            <Button
              type="submit"
              variant="contained"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
