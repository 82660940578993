import { AuthLayout } from "app/auth/components";
import React from "react";
import VerifyEmailIlustration from "assets/verify-email.svg";
import CheckIcon from "@mui/icons-material/Close";
import Logo from "assets/logo.png";

export const EmailVerificationErrorPage = () => {
  return (
    <AuthLayout
      ilustration={VerifyEmailIlustration}
      title="Verificación de email"
      subtitle=""
      logo={Logo}
    >
      <div className="flex max-w-xl flex-col items-center">
        <h1 className="my-4 text-center text-2xl font-bold">
          Hubo un error al verificar su email
        </h1>
        <div className="h-60">
          <div className="flex flex-col items-center transition delay-300 duration-300 ease-in-out">
            <h2>Intente nuevamente más tarde</h2>
            <div className="m-4 rounded-full bg-primary-600 p-4 text-white">
              <CheckIcon />
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
