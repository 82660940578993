import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faDumbbell,
  faEllipsisH,
  faHistory,
  faList,
  faPlusCircle,
  faSignOutAlt,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { SidebarHeaderProfesor, SidebarHeaderSocio, SidebarItem } from ".";
import { useQuery } from "@vadiun/react-hooks";
import { ProfesorRepository } from "app/profesor/services/profesorRepository";
import { useAuthService } from "app/auth/services";
import { UserRole } from "app/auth/models";
import { SocioRepository } from "app/socio/services/socioRepository";
import { Profesor } from "app/profesor/models/Profesor";
import { Socio } from "app/socio/models/Socio";

export type SideBarStatus =
  | {
      platform: "mobile";
      status: "open" | "closed";
    }
  | {
      platform: "desktop";
      status: "collapsed" | "expanded";
    };
interface Props {
  status: SideBarStatus;
  toggleCollapse: () => void;
}
interface PropsSidebarSection {
  titulo: string;
  isCollapsed: boolean;
}

const SidebarSection = ({ titulo, isCollapsed }: PropsSidebarSection) => {
  return (
    <h1 className="text-blueGray-700 mt-7 mb-4 px-8 text-xs font-semibold">
      {!isCollapsed ? titulo : <FontAwesomeIcon icon={faEllipsisH} size="1x" />}
    </h1>
  );
};

const profesorRepo = new ProfesorRepository();
const socioRepo = new SocioRepository();
export const Sidebar = ({ status, toggleCollapse }: Props) => {
  const authSrv = useAuthService();
  const userQuery = useQuery(async () => {
    if (authSrv.userRole === UserRole.PROFESOR) {
      return profesorRepo.getLoggedUser();
    }
    if (authSrv.userRole === UserRole.SOCIO) {
      return socioRepo.getLoggedUser();
    }
  }, [authSrv.userRole]);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [isTemporarilyExpanded, setIsTemporarilyExpanded] = useState(false);
  const location = useLocation();
  const isCollapsed =
    status.platform === "desktop" &&
    status.status === "collapsed" &&
    !isTemporarilyExpanded;

  useEffect(() => {
    setSelectedItem(location.pathname);
  }, [location.pathname]);

  const colapsedClass = () => {
    const baseClass =
      "bg-gray-50 min-h-full shadow-md flex-shrink-0 transition-all duration-300 ease-in-out w-full md:w-60";
    if (status.platform === "mobile") {
      const mobileBaseClass = `${baseClass} fixed`;
      const mobileClosedClass = `${mobileBaseClass} hidden hide-sidebar`;
      return status.status === "closed" ? mobileClosedClass : mobileBaseClass;
    }
    if (status.platform === "desktop") {
      const desktopBaseClass = `${baseClass} block show-sidebar relative`;
      const desktopCollapsedClass = `${desktopBaseClass} w-20`;
      const desktopExpandedClass = `${desktopBaseClass} w-60`;
      return !isCollapsed ? desktopExpandedClass : desktopCollapsedClass;
    }
  };

  const onMouseOver = () => {
    if (status.platform === "desktop" && status.status === "collapsed") {
      setIsTemporarilyExpanded(true);
    }
  };

  const onMouseOut = () => {
    if (status.platform === "desktop" && status.status === "collapsed") {
      setIsTemporarilyExpanded(false);
    }
  };

  return (
    <>
      <div
        className={colapsedClass()}
        style={{ zIndex: 500 }}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseOut}
      >
        {userQuery.data instanceof Profesor ? (
          <SidebarHeaderProfesor
            toggleCollapse={toggleCollapse}
            isCollapsed={isCollapsed}
            isMobile={false}
            profesor={userQuery.data}
          />
        ) : null}
        {userQuery.data instanceof Socio ? (
          <SidebarHeaderSocio
            toggleCollapse={toggleCollapse}
            isCollapsed={isCollapsed}
            isMobile
            socio={userQuery.data}
          />
        ) : null}
        {authSrv.userRole === UserRole.SOCIO ? (
          <div className="mt-8">
            <button onClick={authSrv.logout}>
              <SidebarItem
                icon={<FontAwesomeIcon icon={faSignOutAlt} size="1x" />}
                label="Salir"
                isCollapsed={isCollapsed}
              />
            </button>
            <SidebarItem
              icon={<FontAwesomeIcon icon={faAddressCard} size="1x" />}
              label="Información personal"
              isCollapsed={isCollapsed}
              redirectTo="/main/socios/me/profile"
              onClick={toggleCollapse}
            />
            <SidebarItem
              icon={<FontAwesomeIcon icon={faHistory} size="1x" />}
              label="Plan de entrenamiento"
              isCollapsed={isCollapsed}
              redirectTo="/main/entrenamientos/actual"
              onClick={toggleCollapse}
            />
            <SidebarItem
              icon={<FontAwesomeIcon icon={faDumbbell} size="1x" />}
              label="Historial de entrenamientos"
              isCollapsed={isCollapsed}
              redirectTo="/main/entrenamientos/historial"
              onClick={toggleCollapse}
            />
          </div>
        ) : null}
        {authSrv.userRole === UserRole.PROFESOR ||
        authSrv.userRole === UserRole.PROFESOR_ADMINISTRADOR ? (
          <div className="mt-8">
            <button onClick={authSrv.logout}>
              <SidebarItem
                icon={<FontAwesomeIcon icon={faSignOutAlt} size="1x" />}
                label="Salir"
                isCollapsed={isCollapsed}
              />
            </button>
            <SidebarItem
              icon={<FontAwesomeIcon icon={faHome} size="1x" />}
              label="Inicio"
              isCollapsed={isCollapsed}
              redirectTo="/main/landing"
            />
            <SidebarItem
              icon={<FontAwesomeIcon icon={faPlusCircle} size="1x" />}
              label="Crear socio"
              isCollapsed={isCollapsed}
              redirectTo="/main/socios/alta"
            />
            <SidebarItem
              icon={<FontAwesomeIcon icon={faList} size="1x" />}
              label="Listado de socios"
              isCollapsed={isCollapsed}
              redirectTo="/main/socios/listado"
            />
            <SidebarItem
              icon={<FontAwesomeIcon icon={faDumbbell} size="1x" />}
              label="Planes de entrenamiento"
              isCollapsed={isCollapsed}
              redirectTo="/main/plantilla-entrenamiento/listado"
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
