import React, { FC } from "react";

interface Props {
  ilustration: any;
  title: string;
  subtitle?: string;
  logo: any;
}

export const AuthLayout: FC<Props> = (props) => {
  return (
    <div className="flex h-full w-full">
      <div className="hidden h-full w-1/3 flex-col items-center bg-gray-600 p-12 shadow-md md:flex">
        <img src={props.logo} alt="" />
        <h2 className="mt-24 text-center text-4xl font-bold text-white">
          {props.title}
        </h2>
        <h4 className="mt-8 text-center text-xl font-bold text-white">
          {props.subtitle}
        </h4>
        <img src={props.ilustration} className="mt-auto h-72" alt="" />
      </div>
      <div className="flex flex-grow items-center justify-center bg-white p-4">
        {props.children}
      </div>
    </div>
  );
};
