import { EntrenamientosRepository } from "app/entrenamientos/services/entrenamientosRepository";
import { PageContainer } from "layout/components";
import { useSuperQuery } from "@vadiun/react-hooks";
import { EntrenamientoDetalleComponent } from "app/entrenamientos/components/EntrenamientoDetalleComponent";
import { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { Button } from "@vadiun/react-components";
import { Link } from "react-router-dom";
import { EntrenamientoDetailPrintComponent } from "../components/RutinasDetailPrintComponent";
import * as html2pdf from "html2pdf.js";
import { FaPrint } from "react-icons/fa";

interface Props {
  repo: EntrenamientosRepository;
}

export const EntrenamientoActualSocioPage = ({ repo }: Props) => {
  const [isRecordatorioCalificacionOpen, setIsRecordatorioCalificacionOpen] =
    useState(false);
  const entrenamientoQuery = useSuperQuery(repo.getActualSocio, {
    showSpinner: true,
  });
  const entrenamientosQuery = useSuperQuery(repo.getAllSocio, {
    showSpinner: true,
  });
  const pendienteCalificacion = (entrenamientosQuery.data ?? [])
    .slice(1)
    .find((e) => e.calificacion === null);

  useEffect(() => {
    if (pendienteCalificacion) {
      setIsRecordatorioCalificacionOpen(true);
    }
  }, [pendienteCalificacion]);

  const printEntrenamiento = (id: number) => {
    var element = document.getElementById("print-entrenamiento-" + id);
    html2pdf.default(element, {
      filename: `entrenamiento-actual.pdf`,
    });
  };
  return (
    <PageContainer
      title="Plan de entrenamiento"
      toolbar={
        <Button
          variant="outlined"
          shape="circle"
          onClick={() => printEntrenamiento(entrenamientoQuery.data!.id)}
        >
          <FaPrint />
        </Button>
      }
    >
      <Dialog
        open={isRecordatorioCalificacionOpen}
        onClose={() => setIsRecordatorioCalificacionOpen(false)}
      >
        <div className="p-8">
          <p className="mb-8">Tenés entrenamientos pendientes de calificar.</p>
          <Link to="/main/entrenamientos/historial">
            <Button variant="contained" className="w-full">
              Calificar
            </Button>
          </Link>
        </div>
      </Dialog>
      {entrenamientoQuery.data === undefined ? null : (
        <EntrenamientoDetalleComponent
          entrenamiento={entrenamientoQuery.data}
        />
      )}
      <div className="h-0 overflow-hidden">
        {entrenamientoQuery.data ? (
          <EntrenamientoDetailPrintComponent
            entrenamiento={entrenamientoQuery.data}
          />
        ) : null}
      </div>
    </PageContainer>
  );
};
