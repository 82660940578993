import { Button } from "@vadiun/react-components";
import * as Yup from "yup";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { Select, TextField } from "formik-mui";
import { useSuperMutation } from "@vadiun/react-hooks";
import { PageContainer } from "layout/components";
import { PageBody } from "layout/components/PageBody";
import { AltaSocioFormType } from "../forms/altaSocioForm/AltaSocioFormType";
import { SocioRepository } from "../services/socioRepository";
import { FormControl, MenuItem } from "@mui/material";
import { SEDES } from "../models/Sede";

const Schema: Yup.SchemaOf<AltaSocioFormType> = Yup.object({
  name: Yup.string().required("El nombre es requerido"),
  dni: Yup.string().required("El DNI es requerido"),
  email: Yup.string()
    .required("El email es requerido")
    .email("El email es invalido"),
  nro_socio: Yup.string().required("El número de socio es requerido"),
  sede: Yup.string().required("La sede es requerida"),
});

interface Props {
  repo: SocioRepository;
}

export const AltaSocioPage = (props: Props) => {
  const initialValues: AltaSocioFormType = {
    name: "",
    dni: "",
    email: "",
    nro_socio: "",
  };
  const altaMutation = useSuperMutation(props.repo.create);

  const submit = async (
    values: AltaSocioFormType,
    formikHelpers: FormikHelpers<AltaSocioFormType>
  ) => {
    await altaMutation.mutate(values);

    formikHelpers.resetForm();
  };

  return (
    <PageContainer title="Alta de usuario">
      <PageBody>
        <div className="flex h-full flex-col items-center justify-center ">
          <div className="mb-10">
            <h1 className="mt-4 text-xl font-bold">Datos del socio</h1>
          </div>
          <h1 className="my-4">Insertá</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={submit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit} className="w-full max-w-lg">
                <div className="flex w-full flex-col justify-center">
                  <div className="grid grid-cols-2 gap-4">
                    <Field
                      label="Nombre y Apellido"
                      variant="outlined"
                      className="col-span-1"
                      name="name"
                      component={TextField}
                    />
                    <Field
                      label="DNI"
                      name="dni"
                      autoComplete="new-password"
                      variant="outlined"
                      className="col-span-1"
                      inputProps={{
                        inputMode: "numeric",
                      }}
                      component={TextField}
                    />

                    <Field
                      label="Correo"
                      name="email"
                      autoComplete="new-password"
                      variant="outlined"
                      className="col-span-1"
                      component={TextField}
                    />
                    <Field
                      label="N° de socio"
                      name="nro_socio"
                      autoComplete="new-password"
                      variant="outlined"
                      fullWidth
                      className="col-span-1"
                      inputProps={{
                        inputMode: "numeric",
                      }}
                      component={TextField}
                    />
                    <FormControl fullWidth className="col-span-1">
                      <Field name="sede" label="Sede" component={Select}>
                        {SEDES.map((option) => (
                          <MenuItem value={option}>{option}</MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </div>
                  <div className="my-8">
                    <Button
                      className="w-full"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Crear
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </PageBody>
    </PageContainer>
  );
};
