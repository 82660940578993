import { Profesor } from "app/profesor/models/Profesor";
import { httpClient } from "shared/services/httpClient";

export const getProfesorMapper = (x: any): Profesor => new Profesor(x);

export interface ICreateProfesor {}

export const createProfesorMapper = (x: ICreateProfesor) => x;

export interface IUpdateProfesor {
  id: number;
}

export const updateProfesorMapper = (x: IUpdateProfesor) => x;

export class ProfesorRepository {
  getAll = async () => {
    const res = await httpClient.get("profesores");
    return res.map(getProfesorMapper);
  };

  get = async (id: number) => {
    const res = await httpClient.get(`profesores/${id}`);
    return getProfesorMapper(res);
  };

  getLoggedUser = async () => {
    const res = await httpClient.get(`profesores/me`);
    return getProfesorMapper(res);
  };

  create = async (x: ICreateProfesor) =>
    httpClient.post("profesores", createProfesorMapper(x));

  update = async (x: IUpdateProfesor) =>
    httpClient.put(`profesores/${x.id}`, updateProfesorMapper(x));
}
