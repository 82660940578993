import { Socio } from "app/socio/models/Socio";
import { Link } from "react-router-dom";
import { Button, Chip, Table, TableColumn } from "@vadiun/react-components";
import User from "assets/user.png";
import moment from "moment";
import { Profesor } from "app/profesor/models/Profesor";

interface Props {
  data: Socio[];
  profesor: Profesor | undefined;
}

export const SociosTableComponent = (props: Props) => {
  const data = props.data.map((d) => ({
    ...d,
    vencimiento_ultimo_entrenamiento:
      d.ultimo_entrenamiento?.fecha_vencimiento?.format("DD/MM/YYYY"),
    profesor_ultimo_entrenamiento:
      d.ultimo_entrenamiento === null
        ? undefined
        : d.ultimo_entrenamiento?.profesor?.nombre +
          " " +
          d.ultimo_entrenamiento?.profesor?.apellido,
    entrenamientoVigente:
      d.ultimo_entrenamiento?.fecha_vencimiento === null
        ? null
        : d.ultimo_entrenamiento?.fecha_vencimiento.isAfter(moment())
        ? "SI"
        : "NO",
  }));

  const columns: TableColumn<typeof data[0]>[] = [
    {
      label: "Foto",
      name: "photo",
      options: {
        filter: false,
        customBodyRenderLite: (index: number) =>
          data[index].photo === null ? (
            <img src={User} className="h-12 w-12 object-cover object-center " />
          ) : (
            <img
              src={data[index].photo}
              className="h-12 w-12 rounded-md object-cover object-center"
            />
          ),
      },
    },
    {
      label: "Nombre",
      name: "name",
      options: {
        filterType: "textField",
      },
    },
    {
      label: "Número Socio",
      name: "nro_socio",
      options: { filter: false },
    },
    {
      label: "DNI",
      name: "dni",
      options: {
        filterType: "textField",
      },
    },
    {
      label: "Fecha vencimiento",
      name: "vencimiento_ultimo_entrenamiento",
      options: {
        filter: false,
      },
    },
    {
      label: "Sede",
      name: "sede",
      options: {
        display: false,
      },
    },
    {
      label: "Profesor",
      name: "profesor_ultimo_entrenamiento",
      options: {
        display: false,
        filterList:
          props.profesor === undefined
            ? []
            : [props.profesor.nombre + " " + props.profesor.apellido],
      },
    },
    {
      label: "Plan activo",
      name: "entrenamientoVigente",

      options: {
        customBodyRenderLite: (index) =>
          data[index].entrenamientoVigente === null ? null : data[index]
              .entrenamientoVigente === "SI" ? (
            <Chip color="green">Si</Chip>
          ) : (
            <Chip color="red">No</Chip>
          ),
        customFilterListOptions: { render: (v) => `Activo: ${v}` },
      },
    },

    {
      label: "Acciones",
      name: "nro_socio",
      options: {
        filter: false,
        customBodyRenderLite: (index: number) => (
          <div style={{ display: "flex" }}>
            <Link to={"/main/socios/" + data[index].id}>
              <Button variant="light" color="green">
                Detalle
              </Button>
            </Link>
          </div>
        ),
      },
    },
  ];

  return (
    <div className="w-full">
      <Table
        options={{
          print: false,
          download: false,
          responsive: "standard",
        }}
        title={""}
        data={data}
        columns={columns}
      />
    </div>
  );
};
