import React from "react";
import { useAuthService } from "app/auth/services";
import { Route, Routes } from "react-router-dom";
import {
  ChangePasswordPage,
  EmailVerificationErrorPage,
  EmailVerificationSuccessPage,
  ForgotPasswordPage,
  LoginPage,
  RegisterPage,
} from "app/auth/pages";

export const AuthNavigation = () => {
  const authSrv = useAuthService();
  return (
    <Routes>
      <Route path={"login"} element={<LoginPage authSrv={authSrv} />} />
      <Route path={"register"} element={<RegisterPage />} />
      <Route
        path={"forgot-password"}
        element={<ForgotPasswordPage authSrv={authSrv} />}
      />
      <Route path={"change-password/:token"} element={<ChangePasswordPage />} />
      <Route
        path={"email-verification/successful"}
        element={<EmailVerificationSuccessPage />}
      />
      <Route
        path={"email-verification/unsuccessful"}
        element={<EmailVerificationErrorPage />}
      />
    </Routes>
  );
};
