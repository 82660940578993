import { EjerciciosRepository } from "app/ejercicios/services/ejerciciosRepository";
import { PageContainer } from "layout/components";
import { useSuperQuery } from "@vadiun/react-hooks";
import { useParams } from "react-router-dom";
import { Chip } from "@vadiun/react-components";

interface Props {
  repo: EjerciciosRepository;
}

export const DetalleEjercicioPage = ({ repo }: Props) => {
  const params = useParams();
  const ejercicioQuery = useSuperQuery(() => repo.get(Number(params.id)));
  if (ejercicioQuery.data === undefined) {
    return null;
  }
  return (
    <PageContainer title={ejercicioQuery.data.nombre} child>
      <div className="min-h-full">
        <img
          src={ejercicioQuery.data.imagen}
          className="h-60 w-full object-cover object-center"
        />
        <div className="p-8">
          <h1 className="text-2xl font-bold">{ejercicioQuery.data.nombre}</h1>
          <div className="mt-4 flex flex-wrap gap-2">
            {ejercicioQuery.data.grupos_musculares.map((grupo) => (
              <Chip color="green" size="md" variant="contained">
                {grupo.nombre}
              </Chip>
            ))}
          </div>
          <div className="mt-8">
            <h2 className="text-md mb-2 font-bold">Descripción</h2>
            <p className="text-typo-500">{ejercicioQuery.data.descripcion}</p>
          </div>
          <div className="mt-8">
            <h2 className="text-md mb-2 font-bold">Tipo</h2>
            <Chip color="red" size="md" variant="contained">
              {ejercicioQuery.data.tipo}
            </Chip>
          </div>
          <div className="aspect-w-16 aspect-h-9 mt-10">
            <iframe
              className={`h-full w-full`}
              src={ejercicioQuery.data.link_video}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        </div>
      </div>
    </PageContainer>
  );
};
