import { Moment } from "moment";
import { RutinaEntrenamiento } from "app/entrenamientos/models/RutinaEntrenamiento";
import { Profesor } from "app/profesor/models/Profesor";

interface IEntrenamiento {
  fecha_inicio: Moment;
  fecha_vencimiento: Moment;
  objetivo: string;
  observacion: string;
  rutinas: RutinaEntrenamiento[];
  id: number;
  calificacion: number;
  calificacion_observacion: string;
  profesor: Profesor;
}

export class Entrenamiento {
  fecha_inicio: Moment;
  fecha_vencimiento: Moment;
  objetivo: string;
  observacion: string;
  rutinas: RutinaEntrenamiento[];
  id: number;
  calificacion: number;
  calificacion_observacion: string;
  profesor: Profesor;

  constructor(args: IEntrenamiento) {
    this.fecha_inicio = args.fecha_inicio;
    this.fecha_vencimiento = args.fecha_vencimiento;
    this.objetivo = args.objetivo;
    this.observacion = args.observacion;
    this.rutinas = args.rutinas;
    this.id = args.id;
    this.calificacion = args.calificacion;
    this.calificacion_observacion = args.calificacion_observacion;
    this.profesor = args.profesor;
  }
}
