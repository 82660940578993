import {
  CreateEntrenamientoRoutingType,
  CreateEntrenamientoRutinaFormType,
} from "app/entrenamientos/forms/CreateEntrenamientoForm/CreateEntrenamientoFormType";
import { PlantillaEntrenamiento } from "app/plantilla-entrenamiento/models/PlantillaEntrenamiento";
import moment from "moment";

export type CreatePlantillaEntrenamientoFormType = {
  nombre: string;
  objetivo: string;
  observacion: string;
  rutinas: CreateEntrenamientoRutinaFormType[];
};

export type CreatePlantillaEntrenamientoRoutingType = {
  formValues: CreatePlantillaEntrenamientoFormType;
  filtrosEjercicios: {
    tipo: string[];
    gruposMuscularesId: number[];
  };
  bloqueValues: {
    rutinaDia: number;
    bloqueIndex: number;
    ejercicio_id: number | undefined;
    returnUrl: string;
  };
} | null;

export const mapPlantillaEntrenamientoModelToFormType = (
  plantilla: PlantillaEntrenamiento
): CreatePlantillaEntrenamientoFormType => ({
  ...plantilla,
  rutinas: plantilla.rutinas.map((rutina) => ({
    dia: Number(rutina.dia),
    bloques: rutina.bloques.map((bloque) => ({
      ...bloque,
      ejercicio_id: bloque.ejercicio.id,
      descanso: String(bloque.descanso),
      series: bloque.series.map((serie) => ({
        ...serie,
        cantidad: String(serie.cantidad),
        carga: String(serie.carga),
      })),
    })),
  })),
});

export const createEntrenamientoFormValueRoutingFromPlantilla = (
  plantilla: PlantillaEntrenamiento,
  socioId: number
): CreateEntrenamientoRoutingType => ({
  formValues: {
    ...mapPlantillaEntrenamientoModelToFormType(plantilla),
    id_socio: socioId,
    fecha_inicio: moment().format("YYYY-MM-DD"),
    fecha_vencimiento: moment().add(1, "month").format("YYYY-MM-DD"),
  },
  filtrosEjercicios: {
    tipos: [],
    gruposMuscularesId: [],
  },
  bloqueValues: {
    bloqueIndex: 0,
    rutinaDia: 0,
    ejercicio_id: 0,
    returnUrl: "",
  },
});
