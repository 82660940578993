import { FaClock, FaWeight } from "react-icons/fa";
import { UnidadSerie } from "app/entrenamientos/models/BloqueEjercicio";
import { RutinaEntrenamiento } from "app/entrenamientos/models/RutinaEntrenamiento";

export const RutinasDetailComponent = ({
  rutinas,
}: {
  rutinas: RutinaEntrenamiento[];
}) => {
  return (
    <div className={"p-10"}>
      <ol>
        {rutinas
          .filter((r) => r.bloques.length > 0)
          .map((rutina) => (
            <li>
              <div>
                <h1 className="mb-4 text-lg font-bold">Día {rutina.dia}</h1>
                <div>
                  <ul className="ml-4">
                    {rutina.bloques.map((bloque) => (
                      <div className="mb-6 flex gap-4">
                        <div className={"flex-1"}>
                          <div className={"flex justify-between gap-8"}>
                            <h3 className="">{bloque.ejercicio.nombre}</h3>
                            <p className="text-typo-500 text-sm">
                              {bloque.categoria}
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <FaClock className="text-gray-500" size={12} />
                            <span className="text-typo-500 text-sm">
                              Descanso {bloque.descanso} s.
                            </span>
                          </div>
                          {bloque.series.map((serie, index) => (
                            <div className="flex items-center gap-2">
                              <FaWeight className="text-gray-500" size={12} />
                              <span className="text-typo-500 text-sm">
                                Serie {index + 1}:
                              </span>
                              <span className="text-typo-500 text-sm">
                                {serie.cantidad}{" "}
                                {bloque.unidad_serie === UnidadSerie.MINUTOS
                                  ? "min"
                                  : bloque.unidad_serie === UnidadSerie.SEGUNDOS
                                  ? "seg"
                                  : bloque.unidad_serie === UnidadSerie.METROS
                                  ? "mts"
                                  : "rep"}{" "}
                                x {serie.carga} kg.{" "}
                                {serie.usa_mancuernas ? "por elemento" : ""}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            </li>
          ))}
      </ol>
    </div>
  );
};
