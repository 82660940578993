import { Entrenamiento } from "app/entrenamientos/models/Entrenamiento";
import { Button, Table, TableColumn } from "@vadiun/react-components";
import { Dialog } from "@mui/material";
import { useState } from "react";
import { FaClock, FaEye, FaPrint, FaWeight } from "react-icons/fa";
import { UnidadSerie } from "app/entrenamientos/models/BloqueEjercicio";
import { Link } from "react-router-dom";
import { RutinasDetailComponent } from "app/entrenamientos/components/RutinasDetailComponent";
import moment from "moment";

interface Props {
  data: Entrenamiento[];
  editLink: (id: number) => React.ReactNode;
  onPrint: (id: number) => void;
}

export const HistorialEntrenamientosTableComponent = (props: Props) => {
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selectedEntrenamiento, setSelectedEntrenamiento] = useState<
    Entrenamiento | undefined
  >();
  const data = props.data.map((entrenamiento) => ({
    ...entrenamiento,
    fecha_inicio: entrenamiento.fecha_inicio.format("DD/MM/YYYY"),
    fecha_vencimiento: entrenamiento.fecha_vencimiento.format("DD/MM/YYYY"),
    profesor:
      entrenamiento.profesor.nombre + " " + entrenamiento.profesor.apellido,
  }));

  const columns: TableColumn<typeof data[0]>[] = [
    {
      label: "Inicio",
      name: "fecha_inicio",
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const fecha1 = moment(obj1.data, "DD/MM/YYYY");
            const fecha2 = moment(obj2.data, "DD/MM/YYYY");
            return order === "asc"
              ? fecha1.isAfter(fecha2)
                ? 1
                : -1
              : fecha1.isBefore(fecha2)
              ? 1
              : -1;
          };
        },
      },
    },
    {
      label: "Vencimiento",
      name: "fecha_vencimiento",
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const fecha1 = moment(obj1.data, "DD/MM/YYYY");
            const fecha2 = moment(obj2.data, "DD/MM/YYYY");
            return order === "asc"
              ? fecha1.isAfter(fecha2)
                ? 1
                : -1
              : fecha1.isBefore(fecha2)
              ? 1
              : -1;
          };
        },
      },
    },
    {
      label: "Objetivo",
      name: "objetivo",
    },
    {
      label: "Profesor",
      name: "profesor",
    },
    {
      label: "Acciones",
      name: "id",
      options: {
        filter: false,
        customBodyRenderLite: (index: number) => (
          <div className={"flex gap-2"}>
            <Button
              onClick={() => {
                props.onPrint(props.data[index].id);
              }}
            >
              <FaPrint />
            </Button>
            <Button
              onClick={() => {
                setIsDetailOpen(true);
                setSelectedEntrenamiento(props.data[index]);
              }}
            >
              <FaEye />
            </Button>
            {props.editLink(props.data[index].id)}
          </div>
        ),
      },
    },
  ];

  return (
    <div className="w-full">
      <Table
        options={{
          print: false,
          download: false,
          responsive: "standard",
        }}
        title={""}
        data={data}
        columns={columns}
      />
      {selectedEntrenamiento ? (
        <Dialog
          open={isDetailOpen}
          onClose={() => setIsDetailOpen(false)}
          maxWidth={"lg"}
        >
          <RutinasDetailComponent rutinas={selectedEntrenamiento.rutinas} />
        </Dialog>
      ) : null}
    </div>
  );
};
