import { DetalleEjercicioPage } from "app/ejercicios/pages/DetalleEjercicioPage";
import { EjerciciosRepository } from "app/ejercicios/services/ejerciciosRepository";

import { Route, Routes } from "react-router-dom";

const repo = new EjerciciosRepository();
export const EjerciciosNavigation = () => {
  return (
    <Routes>
      <Route path=":id" element={<DetalleEjercicioPage repo={repo} />} />
    </Routes>
  );
};
