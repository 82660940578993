import "./App.css";
import { AuthProvider } from "app/auth/services/AuthService";
import {
  MessageProvider,
  SnackbarProvider,
  SpinnerProvider,
  VerifyActionProvider,
} from "@vadiun/react-hooks";
import {
  Message,
  Snackbar,
  Spinner,
  VerifyAction,
} from "@vadiun/react-components";
import { SnackbarProvider as NotiStackProvider } from "notistack";
import moment from "moment";
import "moment/locale/es";
import { AppNavigation } from "app/navigation";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";

moment.locale("es");

const theme = createTheme({
  typography: {
    fontFamily: `"Poppins", sans-serif`,
  },
  palette: {
    primary: {
      main: "#094FA3",
    },
    secondary: {
      main: "#094FA3",
    },
  },
});

export function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <NotiStackProvider>
            <SpinnerProvider component={Spinner}>
              <MessageProvider component={Message}>
                <SnackbarProvider component={Snackbar}>
                  <VerifyActionProvider component={VerifyAction}>
                    <AuthProvider>
                      <AppNavigation />
                    </AuthProvider>
                  </VerifyActionProvider>
                </SnackbarProvider>
              </MessageProvider>
            </SpinnerProvider>
          </NotiStackProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
