import { useNavigate } from "react-router-dom";
import { LoginCredentials, UserRole } from "../models/User";
import React, { useContext, useEffect, useState } from "react";
import { useAuthRepository } from "./AuthRepository";
import jwtDecode from "jwt-decode";
import { setUnauthorizedCallback } from "@vadiun/http-client";

export interface AuthContextType {
  isAuthenticated: boolean;
  forgotPassword: (email: string) => Promise<void>;
  login: (x: LoginCredentials) => Promise<string>;
  userRole: UserRole | undefined;
  changePassword: (x: {
    password: string;
    token: string;
    email: string;
  }) => Promise<void>;
  logout: () => void;
}
export const AuthContext = React.createContext({} as AuthContextType);
const _isAuthenticated = () => !!localStorage.getItem("token");

export const AuthProvider = (props: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(_isAuthenticated());
  const [userRole, setUserRole] = useState<UserRole | undefined>(undefined);
  const [userId, setUserId] = useState<number | undefined>(undefined);
  const navigate = useNavigate();
  const authRepo = useAuthRepository();

  useEffect(() => {
    setIsAuthenticated(_isAuthenticated());
    const token = localStorage.getItem("token");
    if (token) {
      updateStateWithToken(token);
    }
  }, []);

  useEffect(() => {
    setUnauthorizedCallback(() => {
      logout();
    });
  }, []);

  function updateStateWithToken(token: string) {
    const decodedUser =
      jwtDecode<{ roles: UserRole[]; user_id: number }>(token);
    setUserRole(decodedUser.roles[0]);
    setUserId(decodedUser.user_id);
  }

  const login = async (x: LoginCredentials) => {
    const { token } = await authRepo.login(x);
    /*const token = "token";*/
    localStorage.setItem("token", token);
    updateStateWithToken(token);
    setIsAuthenticated(true);
    navigate("/main/landing");
    return token;
  };

  const logout = () => {
    localStorage.setItem("token", "");
    setIsAuthenticated(false);
    navigate("login");
  };

  const forgotPassword = (email: string) => authRepo.forgotPassword(email);

  const changePassword = (x: {
    password: string;
    token: string;
    email: string;
  }) => authRepo.changePassword(x);

  const value: AuthContextType = {
    isAuthenticated,
    forgotPassword,
    login,
    changePassword,
    logout,
    userRole,
  };
  return <AuthContext.Provider value={value} {...props} />;
};

export const useAuthService = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth debe estar dentro del proveedor AuthContext");
  }
  return authContext;
};
