import { Button } from "@vadiun/react-components";
import Logo from "assets/logo.png";
import * as Yup from "yup";
import { AuthContextType } from "app/auth/services";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import { useSuperMutation } from "@vadiun/react-hooks";

type FormSchema = {
  email: string;
  password: string;
};

const Schema: Yup.SchemaOf<FormSchema> = Yup.object({
  email: Yup.string()
    .email("El email es invalido")
    .required("El email es requerido"),
  password: Yup.string()
    .required("La contraseña es requerida")
    .min(4, "La contraseña debe tener como minimo 4 caracteres"),
});

interface Props {
  authSrv: AuthContextType;
}

export const LoginPage = (props: Props) => {
  const initialValues: FormSchema = {
    email: "",
    password: "",
  };
  const loginMutation = useSuperMutation(props.authSrv.login);

  const submit = async (
    value: FormSchema,
    formikHelpers: FormikHelpers<FormSchema>
  ) => {
    await loginMutation.mutate(value);

    formikHelpers.resetForm();
  };

  return (
    <div className="flex h-full flex-col items-center justify-center p-12">
      <div>
        <img className="mb-10" src={Logo} alt="" />
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={submit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="w-full max-w-lg">
            <div className="mb-10">
              <h1 className="mt-4 text-2xl font-bold">¡Bienvenido!</h1>
              <span className=" text-gray-300 ">
                Inicia sesión para continuar
              </span>
            </div>
            <div className=" flex flex-col justify-center">
              <Field
                label="Correo"
                variant="outlined"
                margin="normal"
                fullWidth
                className="bg-white"
                name="email"
                component={TextField}
              />
              <Field
                label="Contraseña"
                type="password"
                name="password"
                autoComplete="new-password"
                variant="outlined"
                margin="normal"
                fullWidth
                className="bg-white"
                component={TextField}
              />
              <div className="my-8">
                <Button
                  className="w-full"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Ingreso
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
