import { PlantillaEntrenamientoListadoPage } from "app/plantilla-entrenamiento/pages/PlantillaEntrenamientoListadoPage";
import { PlantillaEntrenamientoRepository } from "app/plantilla-entrenamiento/services/plantillaEntrenamientoRepository";
import { CreatePlantillaEntrenamientoPage } from "app/plantilla-entrenamiento/pages/CreatePlantillaEntrenamientoPage";
import { Route, Routes } from "react-router-dom";
import { ListaEjerciciosPage } from "app/plantilla-entrenamiento/pages/ListaEjerciciosPage";
import { FiltroEjerciciosPage } from "app/plantilla-entrenamiento/pages/FiltrosEjercicioPage";
import { PlantillaEntrenamientoBloqueCreatePage } from "app/plantilla-entrenamiento/pages/PlantillaEntrenamientoBloqueCreatePage";
import { EjerciciosRepository } from "app/ejercicios/services/ejerciciosRepository";
import { EditPlantillaEntrenamientoPage } from "app/plantilla-entrenamiento/pages/EditPlantillaEntrenamientoPage";

const repo = new PlantillaEntrenamientoRepository();
const ejercicioRepo = new EjerciciosRepository();

export const PlantillaEntrenamientoNavigation = () => {
  return (
    <Routes>
      <Route
        path="listado-ejercicios"
        element={<ListaEjerciciosPage repo={ejercicioRepo} />}
      />
      <Route
        path="filtro-ejercicios"
        element={<FiltroEjerciciosPage repo={ejercicioRepo} />}
      />
      <Route
        path="create"
        element={
          <CreatePlantillaEntrenamientoPage
            repo={repo}
            ejerciciosRepo={ejercicioRepo}
          />
        }
      />
      <Route
        path=":id/edit"
        element={
          <EditPlantillaEntrenamientoPage
            repo={repo}
            ejerciciosRepo={ejercicioRepo}
          />
        }
      />
      <Route
        path="bloque/create"
        element={
          <PlantillaEntrenamientoBloqueCreatePage
            ejercicioRepo={ejercicioRepo}
          />
        }
      />
      <Route
        path="listado"
        element={<PlantillaEntrenamientoListadoPage repo={repo} />}
      />
    </Routes>
  );
};
