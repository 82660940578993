import React from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { Button, Chip } from "@vadiun/react-components";
import { createInitialValues } from "./initialValues";
import {
  CreatePlantillaEntrenamientoFormType,
  CreatePlantillaEntrenamientoRoutingType,
} from "./CreatePlantillaEntrenamientoFormType";
import { validation } from "./validation";
import { TextField } from "formik-mui";
import { Chip as MaterialChip } from "@material-ui/core";
import { bloqueEntrenamientoCategoriaColor } from "app/entrenamientos/models/BloqueEjercicio";
import { Ejercicio } from "app/ejercicios/models/Ejercicio";
import { FaChevronDown, FaChevronUp, FaListUl, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Dialog } from "@mui/material";
import { EntrenamientoObjetivoPicker } from "app/entrenamientos/components/EntrenamientoObjetivoPicker";
import { EntrenamientoObservacionPicker } from "app/entrenamientos/components/EntrenamientoObservacionPicker";

interface Props {
  onSubmit: (value: CreatePlantillaEntrenamientoFormType) => Promise<void>;
  initialValues?: CreatePlantillaEntrenamientoFormType;
  ejercicios: Ejercicio[];
  selectedDia: number;
  returnUrl: string;
}

export function CreatePlantillaEntrenamientoForm({
  onSubmit,
  initialValues = createInitialValues(),
  ejercicios,
  selectedDia,
  returnUrl,
}: Props) {
  const [visibleRutina, setVisibleRutina] = React.useState(selectedDia);
  const getEjercicio = (id: number) => ejercicios.find((e) => e.id === id);
  const [visibleObservacionPicker, setVisibleObservacionPicker] =
    React.useState(false);
  const [visibleObjetivoPicker, setVisibleObjetivoPicker] =
    React.useState(false);

  const createRoutingFormValues = (
    values: CreatePlantillaEntrenamientoFormType,
    rutinaDia: number,
    ejercicio_id: number | undefined,
    bloqueIndex: number
  ): CreatePlantillaEntrenamientoRoutingType => {
    return {
      formValues: values,
      filtrosEjercicios: {
        gruposMuscularesId: [],
        tipo: [],
      },
      bloqueValues: {
        rutinaDia,
        bloqueIndex,
        ejercicio_id,
        returnUrl,
      },
    };
  };

  return (
    <Formik<CreatePlantillaEntrenamientoFormType>
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className="grid w-full grid-cols-6 gap-6">
          <Field
            name="nombre"
            label="Nombre *"
            className="col-span-6"
            component={TextField}
          />
          <h2 className="col-span-6">Observaciones</h2>
          <div className="col-span-6 flex gap-4">
            <Field
              name="objetivo"
              label="Objetivo *"
              className="flex-1"
              multiline
              maxRows={4}
              component={TextField}
            />
            <Button
              onClick={() => setVisibleObjetivoPicker(true)}
              variant="light"
            >
              <FaListUl color="white" />
            </Button>
          </div>
          <Dialog
            open={visibleObjetivoPicker}
            onClose={() => setVisibleObjetivoPicker(false)}
          >
            <div className="p-8">
              <EntrenamientoObjetivoPicker
                onSelect={(obs) => {
                  formik.setFieldValue("objetivo", obs);
                  setVisibleObjetivoPicker(false);
                }}
              />
            </div>
          </Dialog>

          <div className="col-span-6 flex gap-4">
            <Field
              name="observacion"
              label="Observación *"
              className="flex-1"
              multiline
              maxRows={4}
              component={TextField}
            />
            <Button
              onClick={() => setVisibleObservacionPicker(true)}
              variant="light"
            >
              <FaListUl color="white" />
            </Button>
          </div>

          <Dialog
            open={visibleObservacionPicker}
            onClose={() => setVisibleObservacionPicker(false)}
          >
            <div className="p-8">
              <EntrenamientoObservacionPicker
                onSelect={(obs) => {
                  formik.setFieldValue("observacion", obs);
                  setVisibleObservacionPicker(false);
                }}
              />
            </div>
          </Dialog>
          <div className="col-span-6 flex justify-center gap-4">
            {formik.values.rutinas.map((rutina) => (
              <MaterialChip
                label={"Día " + rutina.dia}
                color="primary"
                variant={visibleRutina === rutina.dia ? "outlined" : "default"}
                onClick={() => setVisibleRutina(rutina.dia)}
              />
            ))}
          </div>

          {formik.values.rutinas.map((rutina, rutinaIndex) =>
            visibleRutina === rutina.dia ? (
              <div className="col-span-6" key={rutinaIndex}>
                <div className={"mb-8 flex w-full justify-center"}>
                  <Link
                    to={"../filtro-ejercicios"}
                    state={createRoutingFormValues(
                      formik.values,
                      rutina.dia,
                      undefined,
                      rutina.bloques.length
                    )}
                  >
                    <MaterialChip
                      color="primary"
                      label={"+ Agregar nuevo ejercicio"}
                    />
                  </Link>
                </div>
                <FieldArray
                  name={"rutinas." + rutinaIndex + ".bloques"}
                  render={(arrayHelpers) => (
                    <>
                      {rutina.bloques.map((bloque, bloqueIndex) => (
                        <div
                          key={bloqueIndex}
                          className={
                            "mb-8 flex gap-8 rounded-md p-4 shadow-md " +
                            "bg-" +
                            bloqueEntrenamientoCategoriaColor[
                              bloque.categoria
                            ] +
                            "-100"
                          }
                        >
                          <img
                            src={getEjercicio(bloque.ejercicio_id)!.imagen}
                            className="h-32 w-32 rounded-md object-cover object-center"
                            alt={"Ejercicio"}
                          />
                          <div className={"w-full"}>
                            <div className={"flex justify-between"}>
                              <div className="flex items-center gap-4">
                                <h2 className={"text-lg font-bold"}>
                                  {getEjercicio(bloque.ejercicio_id)!.nombre}
                                </h2>
                                <Chip
                                  color={
                                    bloqueEntrenamientoCategoriaColor[
                                      bloque.categoria
                                    ]
                                  }
                                  variant="contained"
                                >
                                  <span className="text-white">
                                    {bloque.categoria}
                                  </span>
                                </Chip>
                              </div>
                              <div className={"flex"}>
                                <Button
                                  shape={"circle"}
                                  disabled={bloqueIndex === 0}
                                  onClick={() => {
                                    arrayHelpers.swap(
                                      bloqueIndex - 1,
                                      bloqueIndex
                                    );
                                  }}
                                >
                                  <FaChevronUp size={14} />
                                </Button>
                                <Button
                                  shape={"circle"}
                                  disabled={
                                    bloqueIndex ===
                                    formik.values.rutinas[rutinaIndex].bloques
                                      .length -
                                      1
                                  }
                                  onClick={() => {
                                    arrayHelpers.swap(
                                      bloqueIndex,
                                      bloqueIndex + 1
                                    );
                                  }}
                                >
                                  <FaChevronDown size={14} />
                                </Button>
                                <Button
                                  shape={"circle"}
                                  onClick={() => {
                                    arrayHelpers.remove(bloqueIndex);
                                  }}
                                >
                                  <FaTimes size={14} />
                                </Button>
                              </div>
                            </div>
                            <div className={"mt-8 flex gap-16"}>
                              <p>Series: {bloque.series.length}</p>
                              <p>Descanso: {bloque.descanso} s</p>
                            </div>
                            <Link
                              to={"../bloque/create"}
                              state={createRoutingFormValues(
                                formik.values,
                                rutina.dia,
                                bloque.ejercicio_id,
                                bloqueIndex
                              )}
                            >
                              <Button className={"ml-auto"}>Ver detalle</Button>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                />
              </div>
            ) : null
          )}

          <div className="col-span-6 flex justify-end">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
