import { EntrenamientosRepository } from "app/entrenamientos/services/entrenamientosRepository";
import { PageContainer } from "layout/components";
import { FaCalendarAlt, FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Button } from "@vadiun/react-components";
import { useSuperMutation, useSuperQuery } from "@vadiun/react-hooks";
import { PageBody } from "layout/components/PageBody";
import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { CalificacionEntrenamientoForm } from "app/entrenamientos/forms/calificacionEntrenamientoForm/CalificacionEntrenamientoForm";

interface Props {
  repo: EntrenamientosRepository;
}

export const HistorialEntrenamientosPage = ({ repo }: Props) => {
  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const [entrenamientoCalificandoId, setEntrenamientoCalificandoId] = useState<
    number | undefined
  >();
  const entrenamientosQuery = useSuperQuery(repo.getAllSocio, {
    showSpinner: true,
  });
  const calificarEntrenamientoMutation = useSuperMutation(repo.calificar, {
    showSpinner: false,
    onMutationFinish: (state) => {
      if (!state.isError) {
        setIsRatingOpen(false);
        entrenamientosQuery.reload();
      }
    },
  });

  if (entrenamientosQuery.data === undefined) {
    return null;
  }
  return (
    <PageContainer title="Historial de entrenamientos">
      <PageBody>
        {entrenamientosQuery.data.map((entrenamiento, index) => (
          <div className="mb-4 flex gap-4">
            <div className="flex h-20 w-20 items-center justify-center rounded-md bg-gray-200">
              {index + 1}
            </div>
            <div className={"flex-1"}>
              <div className={"mb-2 flex items-center justify-between "}>
                <h3 className={"text-sm"}>Plan de entrenamiento</h3>
                {entrenamiento.calificacion === null ? (
                  <FaStar
                    size={20}
                    color={"#fde68a"}
                    onClick={() => {
                      setIsRatingOpen(true);
                      setEntrenamientoCalificandoId(entrenamiento.id);
                    }}
                  />
                ) : (
                  <div className="flex items-center gap-1">
                    <span className={"text-typo-500 text-xs"}>
                      {entrenamiento.calificacion}
                    </span>
                    <FaStar size={20} color={"#fbbf24"} />
                  </div>
                )}
              </div>

              <div className="flex items-center gap-2">
                <FaCalendarAlt className="text-gray-400" size={12} />
                <span className="text-typo-500 text-xs">
                  Inicio {entrenamiento.fecha_inicio.format("DD/MM/YYYY")}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <FaCalendarAlt className="text-gray-400" size={12} />
                <span className="text-typo-500 text-xs">
                  Vencimiento{" "}
                  {entrenamiento.fecha_vencimiento.format("DD/MM/YYYY")}
                </span>
              </div>
              <Link to={"/main/entrenamientos/" + entrenamiento.id}>
                <Button className="ml-auto">Ver detalle</Button>
              </Link>
            </div>
          </div>
        ))}
        <Dialog open={isRatingOpen} onClose={() => setIsRatingOpen(false)}>
          <div className={"w-full min-w-[300px] p-6"}>
            <CalificacionEntrenamientoForm
              onSubmit={(values) =>
                calificarEntrenamientoMutation.mutate({
                  ...values,
                  entrenamiento_id: entrenamientoCalificandoId!,
                })
              }
            />
          </div>
        </Dialog>
      </PageBody>
    </PageContainer>
  );
};
