import React from "react";
import { FaChevronRight } from "react-icons/fa";

interface Props {
  onSelect: (value: string) => void;
}

const observaciones = [
  "Ninguna",
  "Cirugias",
  "Problemas de columna ",
  "Problemas de rodilla",
  "Problemas de brazo",
  "Problemas de hombro",
  "Hernias",
  "Diabetes",
  "Enfermedad cardiovascular",
  "Hipertiroidismo",
  "Hipotiroidismo",
  "Contracturas",
  "Presion alta",
  "Presion baja",
  "Toma algún medicamento",
];
export const EntrenamientoObservacionPicker = ({ onSelect }: Props) => {
  return (
    <div>
      {observaciones.map((obs) => (
        <button
          onClick={() => onSelect(obs)}
          key={obs}
          className="flex w-full items-center justify-between gap-4 border-t-2 border-solid border-gray-100 p-4 text-xs"
        >
          {obs}
          <FaChevronRight />
        </button>
      ))}
    </div>
  );
};
