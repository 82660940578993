interface IProfesor {
  nombre: string;
  apellido: string;
  imagen: string;
}

export class Profesor {
  nombre: string;
  apellido: string;
  imagen: string;

  constructor(args: IProfesor) {
    this.nombre = args.nombre;
    this.apellido = args.apellido;
    this.imagen = args.imagen;
  }
}
