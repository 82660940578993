import React, { useEffect, useRef } from "react";
import { Field, Form, Formik } from "formik";
import { Button } from "@vadiun/react-components";
import { createInitialValues } from "./initialValues";
import { CalificacionEntrenamientoFormType } from "./CalificacionEntrenamientoFormType";
import { validation } from "./validation";
import { TextField, Checkbox, Select, RadioGroup } from "formik-mui";
import { DesktopDateTimePicker } from "formik-mui-lab";
import { MenuItem, Radio, FormControlLabel } from "@mui/material";
import { FaStar } from "react-icons/fa";

interface Props {
  onSubmit: (value: CalificacionEntrenamientoFormType) => Promise<void>;
  initialValues?: CalificacionEntrenamientoFormType;
}

export function CalificacionEntrenamientoForm({
  onSubmit,
  initialValues = createInitialValues(),
}: Props) {
  return (
    <Formik<CalificacionEntrenamientoFormType>
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className="grid w-full grid-cols-6 gap-6">
          <div className={"col-span-6"}>
            <h1 className={"mb-4 text-sm font-bold"}>Calificar</h1>
            <div
              className={"col-span-6 flex items-center justify-center gap-2"}
            >
              <button
                type={"button"}
                onClick={() => formik.setFieldValue("calificacion", 1)}
              >
                {formik.values.calificacion >= 1 ? (
                  <FaStar size={20} color={"#fbbf24"} />
                ) : (
                  <FaStar size={20} color={"#fde68a"} />
                )}
              </button>
              <button
                type={"button"}
                onClick={() => formik.setFieldValue("calificacion", 2)}
              >
                {formik.values.calificacion >= 2 ? (
                  <FaStar size={20} color={"#fbbf24"} />
                ) : (
                  <FaStar size={20} color={"#fde68a"} />
                )}
              </button>
              <button
                type={"button"}
                onClick={() => formik.setFieldValue("calificacion", 3)}
              >
                {formik.values.calificacion >= 3 ? (
                  <FaStar size={20} color={"#fbbf24"} />
                ) : (
                  <FaStar size={20} color={"#fde68a"} />
                )}
              </button>
              <button
                type={"button"}
                onClick={() => formik.setFieldValue("calificacion", 4)}
              >
                {formik.values.calificacion >= 4 ? (
                  <FaStar size={20} color={"#fbbf24"} />
                ) : (
                  <FaStar size={20} color={"#fde68a"} />
                )}
              </button>
              <button
                type={"button"}
                onClick={() => formik.setFieldValue("calificacion", 5)}
              >
                {formik.values.calificacion >= 5 ? (
                  <FaStar size={20} color={"#fbbf24"} />
                ) : (
                  <FaStar size={20} color={"#fde68a"} />
                )}
              </button>
              <span className={"text-xs text-typo-500"}>
                {formik.values.calificacion}
              </span>
            </div>
          </div>
          <div className="col-span-6">
            <h1 className={"mb-4 text-sm"}>Deja tu observación *</h1>
            <Field
              name="calificacion_observacion"
              placeholder="Contános tu experiencia"
              maxRows={4}
              className={"w-full"}
              multiline
              component={TextField}
            />
          </div>

          <div className="col-span-6 flex justify-end">
            <Button
              type="submit"
              variant="contained"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
