import { DetalleEntrenamientoPage } from "app/entrenamientos/pages/DetalleEntrenamientoPage";
import { EntrenamientoActualSocioPage } from "app/entrenamientos/pages/EntrenamientoActualSocioPage";
import { Route, Routes } from "react-router-dom";
import { EntrenamientoPage } from "app/entrenamientos/pages/EntrenamientoPage";
import { SocioRepository } from "app/socio/services/socioRepository";
import { EjerciciosRepository } from "app/ejercicios/services/ejerciciosRepository";
import { FormStepperProvider } from "shared/components";
import { CreateEntrenamientoFormType } from "app/entrenamientos/forms/CreateEntrenamientoForm/CreateEntrenamientoFormType";
import { createInitialValues } from "app/entrenamientos/forms/CreateEntrenamientoForm/initialValues";
import { EntrenamientosRepository } from "../services/entrenamientosRepository";
import { HistorialEntrenamientosPage } from "app/entrenamientos/pages/HistorialEntrenamientosPage";
import { ListaEjerciciosPage } from "app/plantilla-entrenamiento/pages/ListaEjerciciosPage";
import { CreateEntrenamientoPage } from "app/entrenamientos/pages/CreateEntrenamientoPage";
import { FiltroEjerciciosPage } from "app/plantilla-entrenamiento/pages/FiltrosEjercicioPage";
import { EntrenamientoBloqueCreatePage } from "app/entrenamientos/pages/EntrenamientoBloqueCreatePage";
import { EditEntrenamientoPage } from "app/entrenamientos/pages/EditEntrenamientoPage";

const repo = new EntrenamientosRepository();
const socioRepo = new SocioRepository();
const ejercicioRepo = new EjerciciosRepository();
const initialValues = createInitialValues();
export const EntrenamientosNavigation = () => {
  return (
    <FormStepperProvider<CreateEntrenamientoFormType>
      initialValues={initialValues}
    >
      <Routes>
        <Route
          path=":id/rutinas/:dia"
          element={<DetalleEntrenamientoPage repo={repo} />}
        />
        <Route
          path="historial"
          element={<HistorialEntrenamientosPage repo={repo} />}
        />
        <Route
          path="actual"
          element={<EntrenamientoActualSocioPage repo={repo} />}
        />
        <Route
          path="listado-ejercicios"
          element={<ListaEjerciciosPage repo={ejercicioRepo} />}
        />
        <Route
          path="filtro-ejercicios"
          element={<FiltroEjerciciosPage repo={ejercicioRepo} />}
        />

        <Route path=":id" element={<EntrenamientoPage repo={repo} />} />
        <Route
          path="create"
          element={
            <CreateEntrenamientoPage
              repo={repo}
              socioRepo={socioRepo}
              ejerciciosRepo={ejercicioRepo}
            />
          }
        />
        <Route
          path=":id/edit"
          element={
            <EditEntrenamientoPage
              repo={repo}
              socioRepo={socioRepo}
              ejerciciosRepo={ejercicioRepo}
            />
          }
        />
        <Route
          path="bloque/create"
          element={
            <EntrenamientoBloqueCreatePage ejercicioRepo={ejercicioRepo} />
          }
        />
      </Routes>
    </FormStepperProvider>
  );
};
