import { EdicionSocioFormType } from "./EdicionSocioFormType";
import { Sede } from "app/socio/models/Sede";

export const createInitialValues = (): EdicionSocioFormType => ({
  name: "",
  dni: "",
  nro_socio: "",
  email: "",
  sede: Sede.RECOLETA,
});
