import React, { createContext, useContext, useState } from "react";

/**
 * Saves and updates the complete form state. Remember to wrap all the form steps in a FormStepperProvider
 *
 * @example
 * const FirstStepPage = () =>
 *  const navigation = useNavigation()
 *  const formStepper = useFormStepper<FormType>()
 *
 *  const onSubmit = (values: Step1FormType) => {
 *    formStepper.setValues(values) // This merges the previous values with the new values by default
 *    navigation.navigate('secondStep')
 *  }
 *   <Formik<Step1FormType>
 *     innerRef={formStepper.formRef} // This is necessary to save formik state when the user navigates back prior to submitting the form
 *     onSubmit={onSubmit}
 *     initialValues={initialValues}
 *     validationSchema={validation}>
 *     {
 *       formik => (
 *         <Field
 *           name="nombre"
 *           component={FormikTextInput}
 *           label="Nombre"
 *           left={<TextInput.Icon name="person-outline" />}
 *         />
 *        )
 *      }
 * </Formik>
 */
export function useFormStepper<FormType>() {
  const { values, setValues } = useContext(
    FormContext as React.Context<{
      values: FormType;
      setValues: (values: Partial<FormType>) => void;
    }>
  );

  return {
    values,
    setValues,
  };
}

const FormContext = createContext(
  {} as { values: any; setValues: (values: any) => void }
);

export function FormStepperProvider<FormType>({
  initialValues,
  children,
}: {
  initialValues: FormType;
  children: React.ReactNode;
}) {
  const [values, setValues] = useState<FormType>(initialValues);

  return (
    <FormContext.Provider
      value={{
        values,
        setValues: (newValues: Partial<FormType>) =>
          setValues({ ...values, ...newValues }),
      }}
    >
      {children}
    </FormContext.Provider>
  );
}
