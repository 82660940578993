import { PlantillaEntrenamiento } from "app/plantilla-entrenamiento/models/PlantillaEntrenamiento";
import { httpClient } from "shared/services/httpClient";
import {
  BloqueEntrenamientoCategoria,
  UnidadSerie,
} from "app/entrenamientos/models/BloqueEjercicio";
import { getEjercicioMapper } from "app/ejercicios/services/ejerciciosRepository";

export const getPlantillaEntrenamientoMapper = (
  x: any
): PlantillaEntrenamiento =>
  new PlantillaEntrenamiento({
    ...x,
    rutinas: x.rutinas.map((rutina) => ({
      ...rutina,
      bloques: rutina.bloques.map((bloque) => ({
        ...bloque,
        ejercicio: getEjercicioMapper(bloque.ejercicio),
        series: bloque.series.map((serie) => {
          return {
            ...serie,
            usa_mancuernas:
              serie.usa_mancuernas === 1 || serie.usa_mancuernas === true
                ? true
                : false,
          };
        }),
      })),
    })),
  });

export interface ICreatePlantillaEntrenamiento {
  nombre: string;
  objetivo: string;
  observacion: string;
  rutinas: {
    dia: number;
    bloques: {
      ejercicio_id: number;
      descanso: string;
      categoria: BloqueEntrenamientoCategoria;
      unidad_serie: UnidadSerie;
      series: { cantidad: string; carga: string; usa_mancuernas: boolean }[];
    }[];
  }[];
}

export const createPlantillaEntrenamientoMapper = (
  plantilla: ICreatePlantillaEntrenamiento
) => {
  return {
    ...plantilla,
    rutinas: plantilla.rutinas.map((rutina) => ({
      ...rutina,
      dia: String(rutina.dia),
    })),
  };
};

export interface IUpdatePlantillaEntrenamiento
  extends ICreatePlantillaEntrenamiento {
  id: number;
}

export const updatePlantillaEntrenamientoMapper = (
  x: IUpdatePlantillaEntrenamiento
) => ({
  ...createPlantillaEntrenamientoMapper(x),
  id: x.id,
});

export class PlantillaEntrenamientoRepository {
  getAll = async () => {
    const res = await httpClient.get<any[]>(
      "profesores/me/plantillas-entrenamiento"
    );
    return res.map(getPlantillaEntrenamientoMapper);
  };

  get = async (id: number | string) => {
    const res = await this.getAll();
    return getPlantillaEntrenamientoMapper(
      res.find((e) => e.id === Number(id))
    );
  };

  delete = async (id: number | string) =>
    httpClient.delete(`plantillas-entrenamiento/${id}`);

  create = async (x: ICreatePlantillaEntrenamiento) =>
    httpClient.post(
      "plantillas-entrenamiento",
      createPlantillaEntrenamientoMapper(x)
    );

  update = async (x: IUpdatePlantillaEntrenamiento) =>
    httpClient.put(
      `plantillas-entrenamiento/${x.id}`,
      updatePlantillaEntrenamientoMapper(x)
    );
}
