import { Chip } from "@vadiun/react-components";
import { Ejercicio } from "app/ejercicios/models/Ejercicio";
import React from "react";

interface Props {
  ejercicio: Ejercicio;
  children: React.ReactNode;
}
export const EjercicioCardComponent = ({ ejercicio, children }: Props) => {
  return (
    <div className="overflow-hidden rounded-md shadow-[0_3px_3px_-1px_rgba(0,0,0,0.3)]">
      <img
        src={ejercicio.imagen}
        className="h-40 w-full object-cover object-center"
        alt="ejercicio"
      />
      <div className={"p-5"}>
        <h4 className="bold mb-5 ">{ejercicio.nombre}</h4>
        <div className="mt-5 border-t-2">
          <div className="mt-4 flex flex-wrap items-start gap-1">
            {ejercicio.grupos_musculares.map((grupo) => (
              <div className="bg-primary-500 rounded-full p-1 px-3 text-xs text-white">
                {grupo.nombre}
              </div>
            ))}
          </div>
          <div className="mt-5">{children}</div>
        </div>
      </div>
    </div>
  );
};
