import { Ejercicio } from "app/ejercicios/models/Ejercicio";
import { httpClient } from "shared/services/httpClient";
import { GrupoMuscular } from "app/ejercicios/models/GrupoMuscular";

export const getEjercicioMapper = (ejercicio: any): Ejercicio =>
  new Ejercicio({
    ...ejercicio,
    grupos_musculares: ejercicio.grupos_musculares.map(
      (grupo) => new GrupoMuscular(grupo)
    ),
  });

export interface ICreateEjercicio {}

export const createEjerciciosMapper = (x: ICreateEjercicio) => x;

export interface IUpdateEjercicio {
  id: number;
}

export const updateEjerciciosMapper = (x: IUpdateEjercicio) => x;

export class EjerciciosRepository {
  getAll = async (): Promise<Ejercicio[]> => {
    const res = await httpClient.get("ejercicios");
    return res.map(getEjercicioMapper);
  };

  getAllWithDeleted = async (): Promise<Ejercicio[]> => {
    const res = await httpClient.get("ejercicios?trashed=1");
    return res.map(getEjercicioMapper);
  };

  get = async (id: number) => {
    const res = await httpClient.get(`ejercicios/${id}`);
    return getEjercicioMapper(res);
  };

  create = async (x: ICreateEjercicio) =>
    httpClient.post("ejercicios", createEjerciciosMapper(x));

  update = async (x: IUpdateEjercicio) =>
    httpClient.put(`ejercicios/${x.id}`, updateEjerciciosMapper(x));

  getGruposMusculares = async () => {
    const res = await httpClient.get("grupos-musculares");
    return res.map((x) => new GrupoMuscular(x));
  };
}
