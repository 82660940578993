import { PageBody, PageContainer } from "layout/components";
import { FiltrosEjerciciosForm } from "app/entrenamientos/forms/FiltrosEjerciciosForm/FiltrosEjerciciosForm";
import { FiltrosEjerciciosFormType } from "app/entrenamientos/forms/FiltrosEjerciciosForm/FiltrosEjerciciosFormType";
import { useLocation, useNavigate } from "react-router-dom";
import { EjerciciosRepository } from "app/ejercicios/services/ejerciciosRepository";
import { EJERCICIO_TIPOS } from "app/ejercicios/models/Ejercicio";
import { GRUPO_MUSCULAR_CATEGORIAS } from "app/ejercicios/models/GrupoMuscular";
import { useSuperQuery } from "@vadiun/react-hooks";
import { CreateEntrenamientoRoutingType } from "app/entrenamientos/forms/CreateEntrenamientoForm/CreateEntrenamientoFormType";

export const FiltroEjerciciosPage = ({
  repo,
}: {
  repo: EjerciciosRepository;
}) => {
  const gruposMusuclaresQuery = useSuperQuery(repo.getGruposMusculares, {
    showSpinner: true,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as CreateEntrenamientoRoutingType;

  const onSubmit = async (values: FiltrosEjerciciosFormType) => {
    navigate("../listado-ejercicios", {
      state: {
        ...locationState,
        filtrosEjercicios: {
          tipos: values.tipos,
          gruposMuscularesId: values.gruposMuscularesId,
        },
      },
    });
  };
  return (
    <PageContainer title="Filtros" child>
      <PageBody>
        <FiltrosEjerciciosForm
          categoriasList={GRUPO_MUSCULAR_CATEGORIAS}
          onNoFilters={() =>
            navigate("../listado-ejercicios", {
              state: {
                ...locationState,
                filtrosEjercicios: {
                  tipos: null,
                  gruposMuscularesId: null,
                },
              },
            })
          }
          tiposList={EJERCICIO_TIPOS}
          gruposMuscularesList={gruposMusuclaresQuery.data ?? []}
          onSubmit={onSubmit}
        />
      </PageBody>
    </PageContainer>
  );
};
