import { getEntrenamientoMapper } from "app/entrenamientos/services/entrenamientosRepository";
import { Socio } from "app/socio/models/Socio";
import { httpClient } from "shared/services/httpClient";

export const getSocioMapper = (x: any): Socio =>
  new Socio({
    ...x,
    ultimo_entrenamiento:
      x.ultimo_entrenamiento === null
        ? null
        : getEntrenamientoMapper(x.ultimo_entrenamiento),
  });

export interface ICreateSocio {}

export const createSocioMapper = (x: ICreateSocio) => x;

export interface IUpdateSocio {
  id: number;
  name: string;
  dni: string;
  email: string;
  nro_socio: string;
  sede: string;
}
export interface IUpdateLoggedSocio {
  name: string;
  dni: string;
  email: string;
  nro_socio: string;
  photo: File | null | string;
}

export const updateSocioMapper = (x: IUpdateSocio) => x;
export const updateLoggedSocioMapper = (x: IUpdateLoggedSocio) => x;

export class SocioRepository {
  getAll = async () => {
    const res = await httpClient.get("socios");
    return res.map(getSocioMapper);
  };

  get = async (id: number) => {
    const res = await httpClient.get(`socios/${id}`);
    return getSocioMapper(res);
  };

  getLoggedUser = async () => {
    const res = await httpClient.get(`socios/me`);
    return getSocioMapper(res);
  };

  create = async (x: ICreateSocio) =>
    httpClient.post("socios", createSocioMapper(x));

  update = async (x: IUpdateSocio) => {
    return httpClient.put(`socios/${x.id}`, updateSocioMapper(x));
  };

  updateLoggedSocio = async (x: IUpdateLoggedSocio) => {
    return httpClient.post(`socios/me/photo?_method=PUT`, x);
  };
}
