import { FaClock, FaWeight } from "react-icons/fa";
import { UnidadSerie } from "app/entrenamientos/models/BloqueEjercicio";
import { Entrenamiento } from "../models/Entrenamiento";
import { Socio } from "app/socio/models/Socio";

export const EntrenamientoDetailPrintComponent = ({
  socio,
  entrenamiento,
}: {
  socio?: Socio;
  entrenamiento: Entrenamiento;
}) => {
  return (
    <div id={"print-entrenamiento-" + entrenamiento.id} className="p-12">
      {socio ? (
        <div className="flex gap-6">
          <span className="font-bold">{socio.name}</span>
          <span>({socio.nro_socio})</span>
        </div>
      ) : null}

      <p className="text-sm">
        Profesor:
        {` ${entrenamiento.profesor.nombre} ${entrenamiento.profesor.apellido}`}{" "}
      </p>
      <p className="text-sm">
        Vigencia: {entrenamiento.fecha_inicio.format("DD/MM/YYYY")} -{" "}
        {entrenamiento.fecha_vencimiento.format("DD/MM/YYYY")}
      </p>

      <ol>
        {entrenamiento.rutinas
          .filter((r) => r.bloques.length > 0)
          .map((rutina) => (
            <li key={rutina.dia}>
              <div className="border-b-2 border-gray-200 p-6">
                <h1 className="mb-4 text-xs font-bold">Día {rutina.dia}</h1>
                <div>
                  <div className="grid grid-cols-3 gap-4 ">
                    {rutina.bloques.map((bloque, index) => (
                      <div className=" flex gap-4" key={index}>
                        <div className={"flex-1"}>
                          <div className={"mb-4  flex gap-8"}>
                            <h3 className="w-full text-xs">
                              {bloque.ejercicio.nombre}
                            </h3>
                            <p className="text-typo-500 w-42 text-xs">
                              {bloque.categoria}
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="text-typo-500 text-xs">
                              Descanso {bloque.descanso} s.
                            </span>
                          </div>
                          {bloque.series.map((serie, index) => (
                            <div
                              className="flex items-center gap-2"
                              key={index}
                            >
                              <span className="text-typo-500 whitespace-nowrap text-xs">
                                Serie {index + 1}:
                              </span>
                              <span className="text-typo-500 text-xs">
                                {serie.cantidad}{" "}
                                {bloque.unidad_serie === UnidadSerie.MINUTOS
                                  ? "min"
                                  : bloque.unidad_serie === UnidadSerie.SEGUNDOS
                                  ? "seg"
                                  : bloque.unidad_serie === UnidadSerie.METROS
                                  ? "mts"
                                  : "rep"}{" "}
                                x {serie.carga} kg.{" "}
                                {serie.usa_mancuernas ? "por elemento" : ""}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ol>
    </div>
  );
};
