import React, { useState } from "react";
import { Button } from "@vadiun/react-components";
import ChangePasswordIlustration from "assets/change-password.svg";
import Logo from "assets/logo.png";
import { AuthLayout } from "app/auth/components";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useAuthService } from "../services";
import { useSuperMutation } from "@vadiun/react-hooks";
import { TextField } from "formik-mui";
import CheckIcon from "@mui/icons-material/Check";
import { useParams } from "react-router-dom";

interface FormSchema {
  password: string;
  passwordRepeat: string;
  email: string;
}

const Schema = Yup.object().shape({
  password: Yup.string()
    .required("La contraseña es requerida")
    .min(4, "La contraseña debe tener como minimo 4 caracteres"),
  email: Yup.string()
    .email("El email es invalido")
    .required("El email es requerido"),
  passwordRepeat: Yup.string()
    .required("La contraseña es requerida")
    .oneOf([Yup.ref("password")], "Las contraseñas no coinciden")
    .min(4, "La contraseña debe tener como minimo 4 caracteres"),
});

export const ChangePasswordPage = () => {
  const [successPasswordReset, setSuccessPasswordReset] = useState(false);
  const params = useParams<{ token: string }>();
  const authSrv = useAuthService();
  const changePasswordMutation = useSuperMutation(authSrv.changePassword, {
    showSuccessMessage: false,
  });

  const onChangePassword = async (values: {
    password: string;
    email: string;
  }) => {
    await changePasswordMutation.mutate({
      password: values.password,
      email: values.email,
      token: params.token!,
    });
    setSuccessPasswordReset(true);
  };
  return (
    <AuthLayout
      ilustration={ChangePasswordIlustration}
      title="Modificar contraseña"
      subtitle="Solo un paso más para recuperar tu cuenta."
      logo={Logo}
    >
      <div className="flex max-w-xl flex-col items-center">
        {!successPasswordReset ? (
          <>
            <h1 className="my-4 text-center text-2xl font-bold">
              Modificar contraseña
            </h1>
            <p className="font-bold text-gray-500">
              Ingrese una nueva contraseña y repitala
            </p>

            <Formik<FormSchema>
              initialValues={{ email: "", password: "", passwordRepeat: "" }}
              validationSchema={Schema}
              onSubmit={onChangePassword}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit} className="mt-8">
                  <Field
                    component={TextField}
                    name="email"
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    className="bg-white"
                  />
                  <Field
                    component={TextField}
                    name="password"
                    label="Contraseña"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    className="bg-white"
                  />
                  <Field
                    name="passwordRepeat"
                    component={TextField}
                    label="Repita la contraseña"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    className="bg-white"
                  />
                  <div className="my-8 w-full">
                    <Button
                      className="w-full"
                      variant="contained"
                      type="submit"
                      disabled={formik.isSubmitting}
                      isLoading={formik.isSubmitting}
                    >
                      Guardar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <div className="flex max-w-xl flex-col items-center">
            <h1 className="my-4 text-center text-2xl font-bold">
              Su contraseña fue modificada
            </h1>
            <div className="h-60">
              <div className="flex flex-col items-center transition delay-300 duration-300 ease-in-out">
                <h2>Ya puede a Tio Moto con sus nuevas credenciales </h2>
                <div className="m-4 rounded-full bg-primary-600 p-4 text-white">
                  <CheckIcon />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </AuthLayout>
  );
};
