import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React from "react";

interface Props {
  toggleSidebar: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  navigateBack: () => void;
  title?: React.ReactNode;
  child?: boolean;
  toolbar?: React.ReactNode;
}

export const Header = (props: Props) => {
  return (
    <>
      <div
        className="flex flex-grow border-b-2 border-solid border-gray-100 bg-white shadow-lg"
        style={{ zIndex: 510 }}
      >
        <div className="text-primary flex h-16 flex-grow items-center px-3">
          {props.child ? (
            <button className="mr-4 lg:hidden" onClick={props.navigateBack}>
              <ChevronLeftIcon />
            </button>
          ) : (
            <button
              className="mr-4 lg:hidden"
              onClick={(e) => props.toggleSidebar(e)}
            >
              <MenuIcon />
            </button>
          )}
          <p>{props.title}</p>
        </div>
        <div className="mr-5 flex py-3">{props.toolbar}</div>
      </div>
    </>
  );
};
