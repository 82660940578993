import { RutinaEntrenamiento } from "app/entrenamientos/models/RutinaEntrenamiento";

interface IPlantillaEntrenamiento {
  nombre: string;
  objetivo: string;
  observacion: string;
  rutinas: RutinaEntrenamiento[];
  id: number;
}

export class PlantillaEntrenamiento {
  nombre: string;
  objetivo: string;
  observacion: string;
  rutinas: RutinaEntrenamiento[];
  id: number;

  constructor(args: IPlantillaEntrenamiento) {
    this.nombre = args.nombre;
    this.objetivo = args.objetivo;
    this.observacion = args.observacion;
    this.rutinas = args.rutinas;
    this.id = args.id;
  }
}
