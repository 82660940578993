import React, { useEffect } from "react";
import { Field, FieldArray, Form, Formik, useFormikContext } from "formik";
import { Button } from "@vadiun/react-components";
import { createInitialValues } from "./initialValues";
import { EntrenamientoBloqueFormType } from "./EntrenamientoBloqueFormType";
import { validation } from "./validation";
import { Checkbox, RadioGroup, Select, TextField } from "formik-mui";
import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
} from "@mui/material";
import {
  CATEGORIAS_BLOQUE,
  UNIDADES_SERIE,
} from "app/entrenamientos/models/BloqueEjercicio";
import { FormikOnFieldChange } from "shared/components";

interface Props {
  onSubmit: (value: EntrenamientoBloqueFormType) => Promise<void>;
  initialValues?: EntrenamientoBloqueFormType;
}

export function EntrenamientoBloqueForm({
  onSubmit,
  initialValues = createInitialValues(),
}: Props) {
  return (
    <Formik<EntrenamientoBloqueFormType>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className="grid w-full grid-cols-6 gap-6">
          <p className={"col-span-6 text-lg"}>Completá</p>
          <div className="col-span-6 mx-16 grid grid-cols-6 gap-6">
            <Field
              name="cant_series"
              label="Series *"
              className="col-span-3"
              type="number"
              component={TextField}
            />
            <FormikOnFieldChange
              field={"cant_series"}
              onChange={(cantidad) => {
                if (cantidad === undefined || cantidad === "") {
                  return;
                }
                const nextSeries: any[] = [];
                for (let i = 0; i < cantidad; i++) {
                  const serie = formik.values.series[i];
                  if (serie) {
                    nextSeries.push(serie);
                  } else {
                    nextSeries.push({
                      cantidad: "",
                      carga: "",
                      usa_mancuernas: false,
                    });
                  }
                }
                formik.setFieldValue("series", nextSeries);
              }}
            />
            <FormikOnPrimeraSerieChange initialValues={initialValues} />
            <Field
              name="descanso"
              label="Descanso *"
              className="col-span-3"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span className="text-xs">segundos</span>
                  </InputAdornment>
                ),
              }}
              component={TextField}
            />
            <FormControl fullWidth className="col-span-6">
              <Field name="categoria" label="Categoría *" component={Select}>
                {CATEGORIAS_BLOQUE.map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Field>
            </FormControl>
          </div>

          <p className={"col-span-6 mt-6 text-lg"}>Series</p>
          <div className="col-span-6 mx-16 grid grid-cols-6 gap-6">
            <Field
              name="unidad_serie"
              label="Unidad *"
              className="col-span-6"
              component={RadioGroup}
            >
              <div className={"flex justify-between"}>
                {UNIDADES_SERIE.map((option) => (
                  <FormControlLabel
                    value={option}
                    control={<Radio />}
                    label={option}
                  />
                ))}
              </div>
            </Field>

            <FieldArray
              name="series"
              render={(arrayHelpers) =>
                formik.values.series.map((serie, index) => (
                  <div className="col-span-6">
                    <p className={"text-typo-500 mb-4"}>#{index + 1}</p>
                    <div className={" flex items-center gap-4"}>
                      <Field
                        name={`series.${index}.cantidad`}
                        label="Cantidad *"
                        fullWidth
                        type="number"
                        component={TextField}
                      />
                      <Field
                        name={`series.${index}.carga`}
                        label="Carga *"
                        fullWidth
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span className="text-xs">Kgs.</span>
                            </InputAdornment>
                          ),
                        }}
                        component={TextField}
                      />
                      <div className={"flex items-center gap-1"}>
                        <span>x2</span>
                        <Field
                          name={`series.${index}.usa_mancuernas`}
                          label="x2"
                          type={"checkbox"}
                          className="col-span-6"
                          component={Checkbox}
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
            />
          </div>
          <div className="col-span-6 flex justify-end">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export function FormikOnPrimeraSerieChange({
  initialValues,
}: {
  initialValues: EntrenamientoBloqueFormType;
}) {
  const { values, setFieldValue } =
    useFormikContext<EntrenamientoBloqueFormType>();
  React.useEffect(() => {
    if (values.series[0] === undefined) {
      return;
    }
    if (values.series[0].carga === initialValues.series[0]?.carga) {
      return;
    }
    const nextSeries = values.series.map((serie) => ({
      ...serie,
      carga: values.series[0].carga,
    }));
    setFieldValue("series", nextSeries);
  }, [values.series[0]?.carga]);

  React.useEffect(() => {
    if (values.series[0] === undefined) {
      return;
    }
    if (values.series[0].cantidad === initialValues.series[0]?.cantidad) {
      return;
    }
    const nextSeries = values.series.map((serie) => ({
      ...serie,
      cantidad: values.series[0].cantidad,
    }));
    setFieldValue("series", nextSeries);
  }, [values.series[0]?.cantidad]);

  React.useEffect(() => {
    if (values.series[0] === undefined) {
      return;
    }
    if (
      values.series[0].usa_mancuernas ===
      initialValues.series[0]?.usa_mancuernas
    ) {
      return;
    }
    const nextSeries = values.series.map((serie) => ({
      ...serie,
      usa_mancuernas: values.series[0].usa_mancuernas,
    }));
    setFieldValue("series", nextSeries);
  }, [values.series[0]?.usa_mancuernas]);

  return null;
}
