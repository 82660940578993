import { PageContainer } from "layout/components";
import { EntrenamientosRepository } from "app/entrenamientos/services/entrenamientosRepository";
import { Link, useParams } from "react-router-dom";
import { useSuperQuery } from "@vadiun/react-hooks";
import { PageBody } from "layout/components/PageBody";
import { FaClock, FaWeight } from "react-icons/fa";
import {
  BloqueEntrenamientoCategoria,
  bloqueEntrenamientoCategoriaColor,
  UnidadSerie,
} from "app/entrenamientos/models/BloqueEjercicio";
import { Button, Chip } from "@vadiun/react-components";

interface Props {
  repo: EntrenamientosRepository;
}

export const DetalleEntrenamientoPage = ({ repo }: Props) => {
  const params = useParams<{ id: string; dia: string }>();
  const entrenamientoQuery = useSuperQuery(
    () => repo.getActualSocioById(Number(params.id)),
    {
      showSpinner: true,
    }
  );

  if (entrenamientoQuery.data === undefined) {
    return null;
  }

  const rutina = entrenamientoQuery.data.rutinas.find(
    (r) => r.dia === params.dia
  )!;

  return (
    <PageContainer title={"Día " + rutina.dia} child>
      <PageBody className="p-4">
        <div className="flex flex-col gap-4">
          {rutina.bloques.map((bloque) => (
            <div
              className={`flex gap-4 rounded-md p-4 bg-${
                bloqueEntrenamientoCategoriaColor[bloque.categoria]
              }-100`}
            >
              <img
                src={bloque.ejercicio.imagen}
                alt={bloque.ejercicio.nombre}
                className="h-20 w-20 rounded-md object-cover"
              />
              <div className={"flex-1"}>
                <div className="flex items-start justify-between">
                  <h3 className="mr-4 w-full text-sm">
                    {bloque.ejercicio.nombre}
                  </h3>

                  <div
                    className={`flex w-24 items-center justify-center bg-${
                      bloqueEntrenamientoCategoriaColor[bloque.categoria]
                    }-500 rounded-md p-1 text-xs text-white`}
                  >
                    <span className="text-bold text-center text-[11px] text-white">
                      {bloque.categoria}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <FaClock className="text-gray-500" size={12} />
                  <span className="text-typo-500 text-xs">
                    Descanso {bloque.descanso} s.
                  </span>
                </div>
                {bloque.series.map((serie, index) => (
                  <div className="flex items-center gap-2">
                    <FaWeight className="text-gray-500" size={12} />
                    <span className="text-typo-500 text-xs">
                      Serie {index + 1}:
                    </span>
                    <span className="text-typo-500 text-xs">
                      {serie.cantidad}{" "}
                      {bloque.unidad_serie === UnidadSerie.MINUTOS
                        ? "min"
                        : bloque.unidad_serie === UnidadSerie.SEGUNDOS
                        ? "seg"
                        : bloque.unidad_serie === UnidadSerie.METROS
                        ? "mts"
                        : "rep"}{" "}
                      x {serie.carga} kg.{" "}
                      {serie.usa_mancuernas ? "por elemento" : ""}
                    </span>
                  </div>
                ))}
                <Link to={"/main/ejercicios/" + bloque.ejercicio.id}>
                  <Button className="ml-auto">Ver detalle</Button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </PageBody>
    </PageContainer>
  );
};
