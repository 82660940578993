import { EdicionSocioFormType } from "./EdicionSocioFormType";
import * as Yup from "yup";

export const validation: Yup.SchemaOf<EdicionSocioFormType> =
  Yup.object().shape({
    name: Yup.string().required("Requerido"),
    dni: Yup.string().required("Requerido"),
    nro_socio: Yup.string().required("Requerido"),
    email: Yup.string().required("Requerido").email("Email inválido"),
    sede: Yup.string().required("Requerido"),
  });
