import { PageContainer } from "layout/components";
import { EntrenamientoBloqueForm } from "app/entrenamientos/forms/EntrenamientoBloqueForm/EntrenamientoBloqueForm";
import { PageBody } from "layout/components/PageBody";
import { useLocation, useNavigate } from "react-router-dom";
import { useSuperQuery } from "@vadiun/react-hooks";
import { EjerciciosRepository } from "app/ejercicios/services/ejerciciosRepository";
import { CreateEntrenamientoRoutingType } from "app/entrenamientos/forms/CreateEntrenamientoForm/CreateEntrenamientoFormType";
import { useEffect, useState } from "react";
import { createInitialValues } from "app/entrenamientos/forms/EntrenamientoBloqueForm/initialValues";
import { EntrenamientoBloqueFormType } from "app/entrenamientos/forms/EntrenamientoBloqueForm/EntrenamientoBloqueFormType";

interface Props {
  ejercicioRepo: EjerciciosRepository;
}

export const EntrenamientoBloqueCreatePage = ({ ejercicioRepo }: Props) => {
  const [initialValues, setInitialValues] =
    useState<EntrenamientoBloqueFormType>(createInitialValues());
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as CreateEntrenamientoRoutingType;
  const ejercicioId = locationState!.bloqueValues.ejercicio_id;
  const rutina = locationState!.bloqueValues.rutinaDia;
  const ejercicioQuery = useSuperQuery(() => ejercicioRepo.get(ejercicioId!));

  useEffect(() => {
    if (locationState) {
      const bloqueInitialValues = locationState.formValues.rutinas.find(
        (r) => r.dia === rutina
      )!.bloques[locationState.bloqueValues.bloqueIndex];
      if (bloqueInitialValues) {
        setInitialValues({
          ...bloqueInitialValues,
          cant_series: String(bloqueInitialValues.series.length),
        });
      }
    }
  }, []);

  return (
    <PageContainer title="Bloque" child>
      <PageBody>
        <div className="mb-10 flex gap-8">
          <img
            src={ejercicioQuery.data?.imagen}
            alt={"ejercicio"}
            className={"h-40 w-40 rounded-md object-cover object-center"}
          />
          <div>
            <h1 className={"text-xl font-bold"}>
              {ejercicioQuery.data?.nombre}
            </h1>
            <h2 className={"text-typo-500"}>
              {ejercicioQuery.data?.descripcion}
            </h2>
          </div>
        </div>
        <EntrenamientoBloqueForm
          initialValues={initialValues}
          onSubmit={async (values) => {
            if (!locationState) {
              return;
            }
            const nextRutinas = locationState.formValues.rutinas;
            nextRutinas.find((r) => r.dia === rutina)!.bloques[
              locationState.bloqueValues.bloqueIndex
            ] = {
              ...values,
              ejercicio_id: ejercicioId!,
            };
            navigate(locationState.bloqueValues.returnUrl, {
              state: {
                ...locationState,
                formValues: {
                  ...(location.state as any).formValues,
                  rutinas: nextRutinas,
                },
              },
            });
          }}
        />
      </PageBody>
    </PageContainer>
  );
};
