import * as Yup from "yup";
import { FiltrosEjerciciosFormType } from "./FiltrosEjerciciosFormType";

export const validation: Yup.SchemaOf<FiltrosEjerciciosFormType> =
  Yup.object().shape({
    categoria: Yup.string().required("Requerido"),
    tipos: Yup.array(),
    gruposMuscularesId: Yup.array()
      .min(1, "Seleccione un grupo muscular")
      .required("Seleccione un grupo muscular"),
  });
