import React from "react";
import { Field, Form, Formik } from "formik";
import { Button } from "@vadiun/react-components";
import { createInitialValues } from "./initialValues";
import { EdicionSocioFormType } from "./EdicionSocioFormType";
import { validation } from "./validation";
import { Select, TextField } from "formik-mui";
import { FormControl, MenuItem } from "@mui/material";

interface Props {
  onSubmit: (value: EdicionSocioFormType) => Promise<void>;
  initialValues?: EdicionSocioFormType;
  sedeOptions: string[];
}

export function EdicionSocioForm({
  onSubmit,
  initialValues = createInitialValues(),
  sedeOptions,
}: Props) {
  return (
    <Formik<EdicionSocioFormType>
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className="grid w-full grid-cols-6 gap-6">
          <Field
            name="name"
            label="Nombre y apellido *"
            className="col-span-6"
            component={TextField}
          />

          <Field
            name="dni"
            label="Dni *"
            className="col-span-6"
            component={TextField}
            inputmode="numeric"
          />

          <Field
            name="nro_socio"
            label="Nro socio *"
            className="col-span-6"
            component={TextField}
            inputmode="numeric"
          />

          <Field
            name="email"
            label="Email *"
            className="col-span-6"
            component={TextField}
          />

          <FormControl fullWidth className="col-span-6">
            <Field name="sede" label="Sede *" component={Select}>
              {sedeOptions.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Field>
          </FormControl>

          <div className="col-span-6 flex justify-end">
            <Button
              type="submit"
              variant="contained"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
