import { GrupoMuscular } from "app/ejercicios/models/GrupoMuscular";

export enum EjercicioTipo {
  MAQUINA = "Maquina",
  ELEMENTOS = "Elementos",
  PESO_LIBRE = "Peso Libre",
  PESO_CORPORAL = "Peso Corporal",
  FUNCIONAL = "Funcional",
  MOVILIDAD = "Movilidad",
}

export const EJERCICIO_TIPOS = Object.values(EjercicioTipo);

interface IEjercicio {
  id: number;
  nombre: string;
  descripcion: string;
  imagen: string;
  link_video: string;
  tipo: EjercicioTipo;
  grupos_musculares: GrupoMuscular[];
}

export class Ejercicio {
  id: number;
  nombre: string;
  descripcion: string;
  imagen: string;
  link_video: string;
  tipo: EjercicioTipo;
  grupos_musculares: GrupoMuscular[];

  constructor(args: IEjercicio) {
    this.id = args.id;
    this.nombre = args.nombre;
    this.descripcion = args.descripcion;
    this.imagen = args.imagen;
    this.link_video = args.link_video;
    this.tipo = args.tipo;
    this.grupos_musculares = args.grupos_musculares;
  }
}
