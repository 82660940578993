import { Header } from "layout/components";
import { Sidebar, SideBarStatus } from "layout/components/";
import React, { createContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useResponsiveBreakpoint } from "shared/hooks/useResponsiveBreakpoint";

interface Props {
  title?: React.ReactNode;
  child?: boolean;
  toolbar?: React.ReactNode;
  navigateBackTo?: string;
}

export const PageContainer: React.FC<Props> = ({
  title = "",
  child = false,
  children,
  toolbar,
  navigateBackTo,
}) => {
  const navigate = useNavigate();
  const canGoBack = window.history.length > 1;
  return (
    <MainLayoutContext.Consumer>
      {({ dismissVisibleSidebar, setSidebarStatus }) => (
        <div
          className="min-h-full w-full overflow-auto"
          onClick={dismissVisibleSidebar}
        >
          <Header
            toggleSidebar={(
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              setSidebarStatus({ platform: "mobile", status: "open" });
              e.stopPropagation();
            }}
            navigateBack={() => {
              if (navigateBackTo) {
                navigate(navigateBackTo);
                return;
              }
              navigate(-1);
            }}
            title={title}
            child={child && canGoBack}
            toolbar={toolbar}
          />
          <div className="bg-white"></div>
          {children}
        </div>
      )}
    </MainLayoutContext.Consumer>
  );
};

type MainLayoutContextType = {
  dismissVisibleSidebar(): void;
  setSidebarStatus(args: SideBarStatus): void;
};

const MainLayoutContext = createContext({} as MainLayoutContextType);

export const MainLayout = () => {
  const breakpoint = useResponsiveBreakpoint();
  const [sidebarStatus, setSidebarStatus] = useState<SideBarStatus>({
    platform: "desktop",
    status: "expanded",
  });

  useEffect(() => {
    if (breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md") {
      setSidebarStatus((status) => {
        if (status.platform === "desktop")
          return { platform: "mobile", status: "closed" };
        return status;
      });
      return;
    }
    setSidebarStatus((status) => {
      if (status.platform === "mobile")
        return { platform: "desktop", status: "expanded" };
      return status;
    });
  }, [breakpoint]);

  function dismissVisibleSidebar() {
    if (sidebarStatus.platform === "mobile") {
      setSidebarStatus({ platform: "mobile", status: "closed" });
    }
  }

  function toggleCollapse() {
    if ((sidebarStatus.platform = "desktop")) {
      setSidebarStatus((status) =>
        status.status === "expanded"
          ? { platform: "desktop", status: "collapsed" }
          : { platform: "desktop", status: "expanded" }
      );
    }
  }

  return (
    <MainLayoutContext.Provider
      value={{ dismissVisibleSidebar, setSidebarStatus }}
    >
      <div className="flex min-h-full">
        <Sidebar
          status={sidebarStatus}
          toggleCollapse={
            sidebarStatus.platform === "mobile"
              ? dismissVisibleSidebar
              : toggleCollapse
          }
        />

        <div
          className="min-h-full w-full overflow-auto"
          onClick={dismissVisibleSidebar}
        >
          <Outlet />
        </div>
      </div>
    </MainLayoutContext.Provider>
  );
};
