import React from "react";
import { FaChevronRight } from "react-icons/fa";

interface Props {
  onSelect: (value: string) => void;
}

const objetivos = [
  "Bajar de peso",
  "Aumentar masa muscular",
  "Aumentar fuerza",
  "Marcar",
  "Tonificar",
  "Aumentar capacidad cardiovascular",
  "Mejorar postura / Elongación",
  "Mantenerse activo",
  "Otros",
];
export const EntrenamientoObjetivoPicker = ({ onSelect }: Props) => {
  return (
    <div>
      {objetivos.map((obs) => (
        <button
          onClick={() => onSelect(obs)}
          key={obs}
          className="flex w-full items-center justify-between gap-4 border-t-2 border-solid border-gray-100 p-4 text-xs"
        >
          {obs}
          <FaChevronRight />
        </button>
      ))}
    </div>
  );
};
