import { CreateEntrenamientoFormType } from "./CreateEntrenamientoFormType";
import * as Yup from "yup";

export const validation: Yup.SchemaOf<CreateEntrenamientoFormType> =
  Yup.object().shape({
    id_socio: Yup.number().required("El socio es requerido"),
    fecha_inicio: Yup.mixed().required("Requerido") as any,
    fecha_vencimiento: Yup.mixed()
      .required("Requerido")
      .test({
        name: "vencimiento demasiado proximo",
        message:
          "La duración del entrenamiento debe ser de por lo menos 28 días",
        test: (value, context) => {
          if (
            value === undefined ||
            context.parent.fecha_vencimiento === undefined
          ) {
            return true;
          }
          return value.diff(context.parent.fecha_inicio, "days") > 28;
        },
      })
      .test({
        name: "vencimiento demasiado lejano",
        message: "La duración del entrenamiento debe ser de menos de 56 días",
        test: (value, context) => {
          if (
            value === undefined ||
            context.parent.fecha_vencimiento === undefined
          ) {
            return true;
          }
          return value.diff(context.parent.fecha_inicio, "days") <= 56;
        },
      })
      .test({
        name: "vencimiento anterior a inicio",
        message: "El vencimiento debe ser posterior al inicio",
        test: (value, context) => {
          if (
            value === undefined ||
            context.parent.fecha_vencimiento === undefined
          ) {
            return true;
          }
          return value.isAfter(context.parent.fecha_inicio);
        },
      }) as any,
    objetivo: Yup.string().required("Requerido"),
    observacion: Yup.string().required("Requerido"),
    rutinas: Yup.array(),
  });
