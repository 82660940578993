import { AuthNavigation } from "app/auth/navigation";
import { useAuthService } from "app/auth/services";
import { MainNavigation } from "app/main/navigation";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

function RequireAuth({ children }: { children: JSX.Element }) {
  const authService = useAuthService();
  if (!authService.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/login" replace />;
  }

  return children;
}

export const AppNavigation = () => {
  return (
    <Routes>
      <Route path="/auth/*" element={<AuthNavigation />} />
      <Route
        path="/main/*"
        element={
          <RequireAuth>
            <MainNavigation />
          </RequireAuth>
        }
      />
      <Route path="*" element={<Navigate to="main/landing" />} />
    </Routes>
  );
};
