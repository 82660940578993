import { EntrenamientoBloqueFormType } from "./EntrenamientoBloqueFormType";
import {
  BloqueEntrenamientoCategoria,
  UnidadSerie,
} from "app/entrenamientos/models/BloqueEjercicio";

export const createInitialValues = (): EntrenamientoBloqueFormType => ({
  descanso: "",
  categoria: BloqueEntrenamientoCategoria.REGULAR,
  unidad_serie: UnidadSerie.REPETICIONES,
  series: [],
  cant_series: "1",
});
