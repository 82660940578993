import { PlantillaEntrenamientoRepository } from "app/plantilla-entrenamiento/services/plantillaEntrenamientoRepository";
import { PageContainer, PageBody } from "layout/components";
import { Button } from "@vadiun/react-components";
import React, { useState } from "react";
import {
  useSuperMutation,
  useSuperQuery,
  useVerifyAction,
} from "@vadiun/react-hooks";
import { Link } from "react-router-dom";
import { Dialog } from "@mui/material";
import { RutinasDetailComponent } from "app/entrenamientos/components/RutinasDetailComponent";
import { RutinaEntrenamiento } from "app/entrenamientos/models/RutinaEntrenamiento";

interface Props {
  repo: PlantillaEntrenamientoRepository;
}

export const PlantillaEntrenamientoListadoPage = ({ repo }: Props) => {
  const verifyAction = useVerifyAction();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selectedRutinas, setSelectedRutinas] = useState<
    RutinaEntrenamiento[] | undefined
  >();
  const plantillasQuery = useSuperQuery(repo.getAll, { showSpinner: true });
  const deleteMutation = useSuperMutation(repo.delete, {
    showSpinner: true,
  });
  return (
    <PageContainer
      title="Entrenamientos predeterminados"
      toolbar={
        <Link to={"../create"}>
          <Button variant={"contained"}>Nuevo plan predetermindado</Button>
        </Link>
      }
    >
      <PageBody>
        {(plantillasQuery.data ?? []).length === 0 ? (
          <p>No hay ningún plan de entrenamiento predeterminado creado</p>
        ) : null}
        {(plantillasQuery.data ?? []).map((plantilla, plantillaIndex) => (
          <div
            key={plantilla.id}
            className={"mb-8 flex gap-8 rounded-md p-4 shadow-md  "}
          >
            <div className="flex h-24 w-24 items-center justify-center rounded-md bg-gray-200">
              {plantillaIndex + 1}
            </div>
            <div className={"w-full"}>
              <div className={"flex justify-between"}>
                <h2 className={"text-lg font-bold"}>{plantilla.nombre}</h2>
              </div>
              <div className={"mt-4 flex gap-8"}>
                <p>Objetivo: {plantilla.objetivo}</p>
              </div>
              <div className={"flex justify-end gap-4"}>
                <Button
                  onClick={() => {
                    verifyAction({
                      onAccept: async () => {
                        await deleteMutation.mutate(plantilla.id);
                        plantillasQuery.reload();
                      },
                      body: "¿Estás seguro de eliminar este plan?",
                      title: "Eliminar plan",
                    });
                  }}
                  color="red"
                >
                  Borrar
                </Button>
                <Button
                  onClick={() => {
                    setIsDetailOpen(true);
                    setSelectedRutinas(plantilla.rutinas);
                  }}
                >
                  Detalle
                </Button>
                <Link to={"../" + plantilla.id + "/edit"}>
                  <Button>Editar</Button>
                </Link>
              </div>
            </div>
          </div>
        ))}
        {selectedRutinas ? (
          <Dialog
            open={isDetailOpen}
            onClose={() => setIsDetailOpen(false)}
            maxWidth={"lg"}
          >
            <RutinasDetailComponent rutinas={selectedRutinas} />
          </Dialog>
        ) : null}
      </PageBody>
    </PageContainer>
  );
};
