import { PageBody } from "layout/components/PageBody";
import { FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import { Entrenamiento } from "app/entrenamientos/models/Entrenamiento";

interface Props {
  entrenamiento: Entrenamiento;
}
export const EntrenamientoDetalleComponent = ({ entrenamiento }: Props) => {
  return (
    <PageBody>
      <div className="w-full">
        <div className="flex items-center gap-4">
          <FaCalendarAlt className="text-typo-400" />
          <span className="flex-1">Fecha de inicio</span>{" "}
          <span className="text-typo-500">
            {entrenamiento.fecha_inicio.format("DD/MM/YYYY")}
          </span>
        </div>
        <div className="mt-4 flex items-center gap-4">
          <FaCalendarAlt className="text-typo-400" />
          <span className="flex-1">Fecha de vencimiento</span>{" "}
          <span className="text-typo-500">
            {entrenamiento.fecha_vencimiento.format("DD/MM/YYYY")}
          </span>
        </div>
        <div className="mt-8">
          <h2 className="font-bold">Profesor</h2>
          <div className="mt-2 flex items-center gap-4">
            <img
              src={entrenamiento.profesor.imagen}
              alt="Profesor"
              className="h-10 w-10 rounded-full object-cover"
            />
            <p className="text-typo-500 text-sm">
              {entrenamiento.profesor.nombre +
                " " +
                entrenamiento.profesor.apellido}
            </p>
          </div>
        </div>
        <div className="mt-8">
          <h2 className="font-bold">Objetivos</h2>
          <p className="text-typo-500 mt-2 text-sm">{entrenamiento.objetivo}</p>
        </div>
        <div className="mt-8">
          <h2 className="font-bold">Observaciones</h2>
          <p className="text-typo-500 mt-2 text-sm">
            {entrenamiento.observacion}
          </p>
        </div>
        <div className="mt-8 w-full">
          {entrenamiento.rutinas
            .filter((rutina) => rutina.bloques.length > 0)
            .map((rutina) => (
              <Link
                className="mb-4 flex w-full justify-between rounded-md bg-gray-50 p-4"
                key={rutina.dia}
                to={
                  "/main/entrenamientos/" +
                  entrenamiento!.id +
                  "/rutinas/" +
                  rutina.dia
                }
              >
                <span>Día {rutina.dia}</span>
                <ChevronRight />
              </Link>
            ))}
        </div>
      </div>
    </PageBody>
  );
};
