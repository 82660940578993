import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Profesor } from "app/profesor/models/Profesor";
import { Socio } from "app/socio/models/Socio";
import UserImage from "assets/user.png";

interface PropsProfesor {
  isCollapsed: boolean;
  isMobile: boolean;
  toggleCollapse: () => void;
  title?: string;
  profesor: Profesor | undefined;
}

export const SidebarHeaderProfesor = ({
  isCollapsed,
  toggleCollapse,
  isMobile,
  profesor,
}: PropsProfesor) => {
  const navigate = useNavigate();
  if (profesor === undefined) {
    return null;
  }
  return (
    <div
      className={
        "flex items-center bg-gray-50 p-4 " +
        (isCollapsed ? "justify-center" : "justify-between")
      }
    >
      <img
        src={profesor.imagen ?? UserImage}
        className={
          "soft-transition h-20 w-20 rounded-xl object-cover object-center text-xs" +
          (isCollapsed
            ? " w-0 "
            : "mr-4 cursor-pointer text-lg font-black text-gray-50")
        }
        onClick={() => navigate("/main/landing")}
        alt=""
      />
      <span>{profesor.nombre + " " + profesor.apellido}</span>
    </div>
  );
};

interface PropsSocio {
  isCollapsed: boolean;
  isMobile: boolean;
  toggleCollapse: () => void;
  title?: string;
  socio: Socio | undefined;
}
export const SidebarHeaderSocio = ({
  isCollapsed,
  toggleCollapse,
  isMobile,
  socio,
}: PropsSocio) => {
  const navigate = useNavigate();

  return (
    <div
      className={
        "flex items-center gap-4 bg-gray-50 p-4 " +
        (isCollapsed ? "justify-center" : "justify-between")
      }
    >
      <img
        src={socio?.photo ?? UserImage}
        className={
          "soft-transition h-20 w-20 rounded-xl object-cover object-center text-xs" +
          (isCollapsed
            ? " w-0 "
            : "mr-4 cursor-pointer text-lg font-black text-gray-50")
        }
        onClick={() => navigate("/main/landing")}
        alt=""
      />
      <div className={"w-full"}>
        <p className="text-lg font-bold">{socio?.name}</p>
        <p className="text-typo-500 text-sm">Nro socio: {socio?.nro_socio}</p>
        <p className="text-typo-500 text-sm">
          {socio?.cant_asistencias} visualizaciones del plan en el mes
        </p>
      </div>
      <button
        className="text-typo-600 mb-auto focus:outline-none"
        onClick={toggleCollapse}
      >
        <FontAwesomeIcon icon={faTimes} size="lg" />
      </button>
    </div>
  );
};
