export enum GrupoMuscularCategoria {
  TREN_SUPERIOR = "Tren Superior",
  ZONA_MEDIA = "Zona Media",
  TREN_INFERIOR = "Tren Inferior",
  CARDIO = "Cardio",
  NATACION = "Natación",
}

export const GRUPO_MUSCULAR_CATEGORIAS = Object.values(GrupoMuscularCategoria);

interface IGrupoMuscular {
  id: number;
  nombre: string;
  descripcion: string;
  categoria: GrupoMuscularCategoria;
}

export class GrupoMuscular {
  id: number;
  nombre: string;
  descripcion: string;
  categoria: GrupoMuscularCategoria;

  constructor(args: IGrupoMuscular) {
    this.id = args.id;
    this.nombre = args.nombre;
    this.descripcion = args.descripcion;
    this.categoria = args.categoria;
  }
}
