import { Ejercicio } from "app/ejercicios/models/Ejercicio";
import { SerieBloque } from "app/entrenamientos/models/SerieBloque";

export enum UnidadSerie {
  SEGUNDOS = "Segundos",
  MINUTOS = "Minutos",
  REPETICIONES = "Repeticiones",
  METROS = "Metros",
}

export const UNIDADES_SERIE = Object.values(UnidadSerie);

export enum BloqueEntrenamientoCategoria {
  HIT_1 = "Hit 1",
  HIT_2 = "Hit 2",
  REGULAR = "Regular",
  ENTRADA_EN_CALOR = "Entrada en calor",
  VUELTA_A_LA_CALMA = "Vuelta a la calma",
  SUPERSERIE_1 = "Superserie 1",
  SUPERSERIE_2 = "Superserie 2",
  SUPERSERIE_3 = "Superserie 3",
  CIRCUITO_1 = "Circuito 1",
  CIRCUITO_2 = "Circuito 2",
}

export const bloqueEntrenamientoCategoriaColor = {
  [BloqueEntrenamientoCategoria.HIT_1]: "red",
  [BloqueEntrenamientoCategoria.HIT_2]: "orange",
  [BloqueEntrenamientoCategoria.REGULAR]: "gray",
  [BloqueEntrenamientoCategoria.ENTRADA_EN_CALOR]: "purple",
  [BloqueEntrenamientoCategoria.VUELTA_A_LA_CALMA]: "secondary",
  [BloqueEntrenamientoCategoria.SUPERSERIE_1]: "indigo",
  [BloqueEntrenamientoCategoria.SUPERSERIE_2]: "primary",
  [BloqueEntrenamientoCategoria.SUPERSERIE_3]: "pink",
  [BloqueEntrenamientoCategoria.CIRCUITO_1]: "esmerald",
  [BloqueEntrenamientoCategoria.CIRCUITO_2]: "green",
};

export const CATEGORIAS_BLOQUE = Object.values(BloqueEntrenamientoCategoria);

interface IBloqueEjercicio {
  ejercicio: Ejercicio;
  descanso: number;
  categoria: BloqueEntrenamientoCategoria;
  unidad_serie: UnidadSerie;
  series: SerieBloque[];
}

export class BloqueEjercicio {
  ejercicio: Ejercicio;
  descanso: number;
  categoria: BloqueEntrenamientoCategoria;
  unidad_serie: UnidadSerie;
  series: SerieBloque[];

  constructor(args: IBloqueEjercicio) {
    this.ejercicio = args.ejercicio;
    this.descanso = args.descanso;
    this.categoria = args.categoria;
    this.unidad_serie = args.unidad_serie;
    this.series = args.series;
  }
}
