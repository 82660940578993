import { SocioRepository } from "app/socio/services/socioRepository";
import { PageContainer } from "layout/components";
import { PageBody } from "layout/components/PageBody";
import { useSuperMutation, useSuperQuery } from "@vadiun/react-hooks";
import { Link, useParams } from "react-router-dom";
import { FaChevronRight, FaPen, FaUser } from "react-icons/fa";
import { Button } from "@vadiun/react-components";
import { Dialog, Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useState } from "react";
import { EdicionSocioForm } from "app/socio/forms/edicionSocioForm/EdicionSocioForm";
import { SEDES } from "app/socio/models/Sede";
import UserImg from "assets/user.png";
import { EntrenamientosRepository } from "app/entrenamientos/services/entrenamientosRepository";
import { HistorialEntrenamientosTableComponent } from "app/entrenamientos/components/HistorialEntrenamientosTableComponent";
import { PlantillaEntrenamientoRepository } from "app/plantilla-entrenamiento/services/plantillaEntrenamientoRepository";
import { createEntrenamientoFormValueRoutingFromPlantilla } from "app/plantilla-entrenamiento/forms/CreatePlantillaEntrenamientoForm/CreatePlantillaEntrenamientoFormType";
import { EntrenamientoDetailPrintComponent } from "app/entrenamientos/components/RutinasDetailPrintComponent";
import * as html2pdf from "html2pdf.js";
import { Entrenamiento } from "app/entrenamientos/models/Entrenamiento";

interface Props {
  repo: SocioRepository;
  entrenamientosRepo: EntrenamientosRepository;
  plantillaEntrenamientoRepo: PlantillaEntrenamientoRepository;
}

export const PerfilSocioPage = ({
  repo,
  entrenamientosRepo,
  plantillaEntrenamientoRepo,
}: Props) => {
  const params = useParams<{ id: string }>();
  const socioQuery = useSuperQuery(() => repo.get(Number(params.id)), {
    showSpinner: true,
  });
  const editarSocioMutation = useSuperMutation(repo.update);
  const entrenamientosQuery = useSuperQuery(() =>
    entrenamientosRepo.getAllBySocioId(Number(params.id))
  );
  const plantillasEntrenamientoQuery = useSuperQuery(
    plantillaEntrenamientoRepo.getAll,
    { showSpinner: true }
  );

  const printEntrenamiento = (id: number) => {
    var element = document.getElementById("print-entrenamiento-" + id);
    html2pdf.default(element, {
      filename: `${socioQuery.data?.name}.pdf`,
    });
  };

  const [selectedTab, setSelectedTab] = useState("0");
  const [isSelectPlantillaOpen, setIsSelectPlantillaOpen] = useState(false);

  return (
    <>
      <PageContainer
        title="Información general"
        child
        navigateBackTo={"/main/socios/listado"}
      >
        <PageBody>
          <div className="mb-6 flex w-full gap-6">
            <img
              src={socioQuery.data?.photo ?? UserImg}
              alt="socio"
              className="h-28 w-28 rounded-xl object-cover object-center"
            />
            <div>
              <h2>{socioQuery.data?.name}</h2>
              <div className="flex items-center gap-1">
                <FaUser size={12} />
                <span className="text-typo-500 text-sm">
                  Nro socio: {socioQuery.data?.nro_socio}
                </span>
              </div>
              <span className="text-typo-500 text-sm">
                Visualizaciones del plan en el mes:{" "}
                {socioQuery.data?.cant_asistencias}
              </span>
            </div>
            <div className="flex flex-1 flex-col items-end gap-2">
              <Link
                to={
                  "/main/entrenamientos/create?id_socio=" + socioQuery.data?.id
                }
              >
                <Button variant="contained">Nuevo plan de entrenamiento</Button>
              </Link>
              <Button
                variant="contained"
                onClick={() => setIsSelectPlantillaOpen(true)}
              >
                Plan de entrenamiento predeterminado
              </Button>
            </div>
          </div>
          <TabContext value={selectedTab}>
            <Tabs
              value={selectedTab}
              onChange={(ev, value) => setSelectedTab(value)}
            >
              <Tab label="Historial de entrenamientos" value={"0"} />
              <Tab label="Información general" value={"1"} />
            </Tabs>

            <TabPanel value={"0"}>
              <HistorialEntrenamientosTableComponent
                data={entrenamientosQuery.data ?? []}
                onPrint={printEntrenamiento}
                editLink={(id: number) => (
                  <Link
                    to={
                      "/main/entrenamientos/" +
                      id +
                      "/edit?id_socio=" +
                      socioQuery.data?.id
                    }
                  >
                    <Button>
                      <FaPen />
                    </Button>
                  </Link>
                )}
              />
            </TabPanel>
            <TabPanel value={"1"}>
              <h3 className=" mb-8">Datos del socio</h3>
              {socioQuery.data ? (
                <EdicionSocioForm
                  onSubmit={(values) =>
                    editarSocioMutation.mutate({
                      ...values,
                      id: Number(params.id),
                    })
                  }
                  initialValues={socioQuery.data}
                  sedeOptions={SEDES}
                />
              ) : null}
            </TabPanel>
          </TabContext>
          <Dialog
            open={isSelectPlantillaOpen}
            onClose={() => setIsSelectPlantillaOpen(false)}
          >
            <div className={"min-w-[500px] p-8"}>
              {(plantillasEntrenamientoQuery.data ?? []).map((plantilla) => (
                <Link
                  to={
                    "/main/entrenamientos/create?id_socio=" +
                    socioQuery.data?.id
                  }
                  state={createEntrenamientoFormValueRoutingFromPlantilla(
                    plantilla,
                    Number(params.id)
                  )}
                >
                  <button
                    className={
                      "flex min-w-full items-center justify-between border-b-2 border-b-gray-200 py-4"
                    }
                  >
                    <span>{plantilla.nombre}</span>
                    <FaChevronRight size={15} />
                  </button>
                </Link>
              ))}
            </div>
          </Dialog>
        </PageBody>
      </PageContainer>
      <div className="h-0 overflow-hidden">
        {socioQuery.data === undefined
          ? null
          : (entrenamientosQuery.data ?? []).map((e) => (
              <EntrenamientoDetailPrintComponent
                entrenamiento={e}
                socio={socioQuery.data!}
              />
            ))}
      </div>
    </>
  );
};
