import { useSuperQuery } from "@vadiun/react-hooks";
import { ProfesorRepository } from "app/profesor/services/profesorRepository";
import { PageContainer } from "layout/components";
import { SociosTableComponent } from "../components/SociosTableComponent";
import { SocioRepository } from "../services/socioRepository";

interface Props {
  repo: SocioRepository;
  repoProfe: ProfesorRepository;
}

export const ListaSociosPage = ({ repo, repoProfe }: Props) => {
  const socioQuery = useSuperQuery(() => repo.getAll(), {
    showSpinner: true,
  });
  const profeQuery = useSuperQuery(() => repoProfe.getLoggedUser(), {
    showSpinner: true,
  });
  return (
    <div>
      <PageContainer title="Listado de socios">
        <SociosTableComponent
          data={socioQuery.data ?? []}
          profesor={profeQuery.data}
        />
      </PageContainer>
    </div>
  );
};
