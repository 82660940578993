import { EntrenamientosRepository } from "app/entrenamientos/services/entrenamientosRepository";
import { PageContainer } from "layout/components";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import UserImg from "assets/user.png";
import { FaUser } from "react-icons/fa";
import { PageBody } from "layout/components/PageBody";
import { SocioRepository } from "app/socio/services/socioRepository";
import { useSuperMutation, useSuperQuery } from "@vadiun/react-hooks";
import { CreateEntrenamientoForm } from "app/entrenamientos/forms/CreateEntrenamientoForm/CreateEntrenamientoForm";
import {
  CreateEntrenamientoFormType,
  CreateEntrenamientoRoutingType,
} from "app/entrenamientos/forms/CreateEntrenamientoForm/CreateEntrenamientoFormType";
import { EjerciciosRepository } from "app/ejercicios/services/ejerciciosRepository";
import { useEffect, useState } from "react";
import { createInitialValues } from "app/entrenamientos/forms/CreateEntrenamientoForm/initialValues";
import moment from "moment";

interface Props {
  repo: EntrenamientosRepository;
  socioRepo: SocioRepository;
  ejerciciosRepo: EjerciciosRepository;
}

export const CreateEntrenamientoPage = ({
  repo,
  socioRepo,
  ejerciciosRepo,
}: Props) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [initialValues, setInitialValues] =
    useState<CreateEntrenamientoFormType>({
      ...createInitialValues(),
      id_socio: Number(searchParams.get("id_socio")!),
    });
  const location = useLocation();
  const locationState = location.state as CreateEntrenamientoRoutingType;

  const createEntrenamientoMutation = useSuperMutation(repo.create, {
    showSpinner: true,
  });
  useEffect(() => {
    if (locationState) {
      setInitialValues({
        ...locationState.formValues,
        fecha_inicio: moment(locationState.formValues.fecha_inicio),
        fecha_vencimiento: moment(locationState.formValues.fecha_vencimiento),
      });
    }
  }, []);

  const socioQuery = useSuperQuery(
    () => socioRepo.get(Number(searchParams.get("id_socio"))),
    { showSpinner: true }
  );
  const ejerciciosQuery = useSuperQuery(ejerciciosRepo.getAll, {
    showSpinner: true,
  });

  return (
    <PageContainer
      title={"Nuevo plan de entrenamientos"}
      child
      navigateBackTo={"/main/socios/" + searchParams.get("id_socio")}
    >
      <PageBody>
        <div className="mb-6 flex w-full gap-6">
          <img
            src={socioQuery.data?.photo ?? UserImg}
            alt="socio"
            className="h-28 w-28 rounded-xl object-cover object-center"
          />
          <div>
            <h2>{socioQuery.data?.name}</h2>
            <div className="flex items-center gap-1">
              <FaUser size={12} />
              <span className="text-typo-500 text-sm">
                Nro socio: {socioQuery.data?.nro_socio}
              </span>
            </div>
            <span className="text-typo-500 text-sm">
              Visualizaciones del plan en el mes:{" "}
              {socioQuery.data?.cant_asistencias}
            </span>
          </div>
        </div>
        {ejerciciosQuery.data && (
          <CreateEntrenamientoForm
            onSubmit={async (values) => {
              await createEntrenamientoMutation.mutate({
                ...values,
                socio_id: values.id_socio,
              });
              navigate("/main/socios/" + searchParams.get("id_socio"));
            }}
            ejercicios={ejerciciosQuery.data ?? []}
            selectedDia={locationState?.bloqueValues.rutinaDia ?? 1}
            initialValues={initialValues}
            returnUrl={
              "/main/entrenamientos/create?id_socio=" +
              searchParams.get("id_socio")
            }
          />
        )}
      </PageBody>
    </PageContainer>
  );
};
