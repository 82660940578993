import * as Yup from "yup";
import { CreatePlantillaEntrenamientoFormType } from "app/plantilla-entrenamiento/forms/CreatePlantillaEntrenamientoForm/CreatePlantillaEntrenamientoFormType";

export const validation: Yup.SchemaOf<CreatePlantillaEntrenamientoFormType> =
  Yup.object().shape({
    nombre: Yup.string().required("El nombre es requerido"),
    objetivo: Yup.string().required("Requerido"),
    observacion: Yup.string().required("Requerido"),
    rutinas: Yup.array(),
  });
