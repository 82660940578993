import { EntrenamientoBloqueFormType } from "./EntrenamientoBloqueFormType";
import * as Yup from "yup";

export const validation: Yup.SchemaOf<EntrenamientoBloqueFormType> =
  Yup.object().shape({
    cant_series: Yup.string()
      .required()
      .test({
        name: "max",
        message: "Máximo 10",
        test: (value) => Number(value) <= 10,
      })
      .test({
        name: "min",
        message: "Mínimo 1",
        test: (value) => Number(value) >= 1,
      }),
    descanso: Yup.string()
      .required("Requerido")
      .test({
        name: "max",
        message: "Máximo 240",
        test: (value) => Number(value) <= 240,
      })
      .test({
        name: "min",
        message: "Mínimo 0",
        test: (value) => Number(value) >= 0,
      }),
    categoria: Yup.string().required("Requerido") as any,
    unidad_serie: Yup.string().required("Requerido") as any,
    series: Yup.array()
      .of(
        Yup.object().shape({
          cantidad: Yup.string()
            .required("Requerido")
            .test({
              name: "max",
              message: "Máximo 2000",
              test: (value) => Number(value) <= 2000,
            })
            .test({
              name: "min",
              message: "Mínimo 1",
              test: (value) => Number(value) >= 1,
            }),
          carga: Yup.string()
            .required("Requerido")
            .test({
              name: "max",
              message: "Máximo 300",
              test: (value) => Number(value) <= 300,
            })
            .test({
              name: "min",
              message: "Mínimo 0",
              test: (value) => Number(value) >= 0,
            }),
          usa_mancuernas: Yup.boolean().required("Requerido"),
        })
      )
      .min(1, "Debe haber al menos una serie"),
  });
