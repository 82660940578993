import { PageContainer } from "layout/components";
import { PageBody } from "layout/components/PageBody";
import Logo from "assets/logo.png";

export const Landing = () => {
  return (
    <PageContainer title="Inicio">
      <PageBody>
        <div className="flex h-full w-full items-center justify-center pt-48">
          <img src={Logo} />
        </div>
      </PageBody>
    </PageContainer>
  );
};
