import { SocioRepository } from "app/socio/services/socioRepository";
import { PageContainer } from "layout/components";
import { useSuperMutation, useSuperQuery } from "@vadiun/react-hooks";
import { PageBody } from "layout/components/PageBody";
import { EdicionSocioForm } from "app/socio/forms/edicionDatosPersonalesForm/EdicionSocioForm";

interface Props {
  repo: SocioRepository;
}

export const MiPerfilPage = ({ repo }: Props) => {
  const socioQuery = useSuperQuery(repo.getLoggedUser, { showSpinner: true });
  const editarSocioMutation = useSuperMutation(repo.updateLoggedSocio);

  return (
    <PageContainer title="Datos personales">
      <PageBody>
        <EdicionSocioForm
          onSubmit={editarSocioMutation.mutate}
          initialValues={socioQuery.data}
        />
      </PageBody>
    </PageContainer>
  );
};
