import { Entrenamiento } from "app/entrenamientos/models/Entrenamiento";
import { httpClient } from "shared/services/httpClient";
import moment, { Moment } from "moment";
import { getEjercicioMapper } from "app/ejercicios/services/ejerciciosRepository";
import {
  BloqueEntrenamientoCategoria,
  UnidadSerie,
} from "app/entrenamientos/models/BloqueEjercicio";
import { Profesor } from "app/profesor/models/Profesor";

export const getEntrenamientoMapper = (x: any): Entrenamiento =>
  new Entrenamiento({
    ...x,
    fecha_inicio: moment(x.fecha_inicio),
    fecha_vencimiento: moment(x.fecha_vencimiento),
    profesor: new Profesor(x.profesor),
    rutinas: x.rutinas.map((rutina) => ({
      ...rutina,
      bloques: rutina.bloques.map((bloque) => ({
        ...bloque,
        ejercicio: getEjercicioMapper(bloque.ejercicio),
        series: bloque.series.map((serie) => ({
          ...serie,
          usa_mancuernas:
            serie.usa_mancuernas === 1 || serie.usa_mancuernas === true
              ? true
              : false,
        })),
      })),
    })),
  });

export interface ICreateEntrenamientos {
  socio_id: number;
  fecha_inicio: Moment;
  fecha_vencimiento: Moment;
  objetivo: string;
  observacion: string;
  rutinas: {
    dia: number;
    bloques: {
      ejercicio_id: number;
      descanso: string;
      categoria: BloqueEntrenamientoCategoria;
      unidad_serie: UnidadSerie;
      series: { cantidad: string; carga: string; usa_mancuernas: boolean }[];
    }[];
  }[];
}

export const createEntrenamientosMapper = (
  entrenamiento: ICreateEntrenamientos
) => {
  return {
    ...entrenamiento,
    rutinas: entrenamiento.rutinas.map((rutina) => ({
      ...rutina,
      dia: String(rutina.dia),
    })),
  };
};

export interface IUpdateEntrenamientos extends ICreateEntrenamientos {
  id: number;
}

export const updateEntrenamientosMapper = (x: IUpdateEntrenamientos) => ({
  ...createEntrenamientosMapper(x),
  id: x.id,
});

export interface ICalificacionEntrenamiento {
  entrenamiento_id: number;
  calificacion: number;
  calificacion_observacion: string;
}

export class EntrenamientosRepository {
  getAll = async () => {
    const res = await httpClient.get("entrenamientos");
    return res.map(getEntrenamientoMapper);
  };

  getActualSocioById = async (id: number) => {
    const res = await httpClient.get(`socios/me/entrenamientos`);
    return getEntrenamientoMapper(res.find((e) => e.id === id));
  };

  create = async (x: ICreateEntrenamientos) =>
    httpClient.post("entrenamientos", createEntrenamientosMapper(x));

  update = async (x: IUpdateEntrenamientos) =>
    httpClient.put(`entrenamientos/${x.id}`, updateEntrenamientosMapper(x));

  getActualSocio = async () => {
    const res = await httpClient.get(`socios/me/entrenamientos`);
    const noVencidos = res.filter((e) =>
      moment(e.fecha_vencimiento, "YYYY-MM-DD").isAfter(moment())
    );
    if (noVencidos[0] === undefined && res[0] === undefined) {
      return undefined;
    }
    return getEntrenamientoMapper(noVencidos[0] ?? res[0]);
  };

  getAllSocio = async () => {
    const res = await httpClient.get<any[]>(`socios/me/entrenamientos`);
    return res.map(getEntrenamientoMapper);
  };

  getAllBySocioId = async (idSocio: number | string) => {
    const res = await httpClient.get<any[]>(`socios/${idSocio}/entrenamientos`);
    return res.map(getEntrenamientoMapper);
  };

  get = async (id: string | number, idSocio: string | number) => {
    const res = await this.getAllBySocioId(idSocio);
    return res.find((e) => e.id === Number(id));
  };

  calificar = async (calificacion: ICalificacionEntrenamiento) =>
    httpClient.post(
      `entrenamientos/${calificacion.entrenamiento_id}/calificaciones`,
      calificacion
    );
}
